<script lang="ts">
	import type { Order, DeliveryWindow } from "~/api";
	import { modifyDocument } from "~/api";
	import { selectedOrder } from "~/stores/administration";
	import OrderedProductText from "~/components/content/OrderedProductText.svelte";

	// Given delivery window
	export let deliveryWindow: DeliveryWindow;

	// Given order
	export let order: Order;

	// Is this the currently selected order?
	$: selected = !!order && !!$selectedOrder && order.id == $selectedOrder.id;

	// Event handlers

	// Update the delivery status of the order
	const handleChangeDeliveryStatus =
		(order: Order, newStatus: Order["deliveryStatus"]) => () => {
			if (
				newStatus == "aborted" &&
				!confirm("Är du säker? En avbruten order går inte att återställa!")
			) {
				return;
			}

			modifyDocument("orders", order.id, {
				deliveryStatus: newStatus,
			});
		};

	// Update the administrators notes of the order
	const handleNotes = (order: Order) => () => {
		const newNotes = prompt("Test", order.administratorNotes || "") || order.administratorNotes || "";

		modifyDocument("orders", order.id, {
			administratorNotes: newNotes,
		});
	};
</script>

<tr
	class="top-row"
	class:has-background-danger-light={order.deliveryStatus == "aborted"}
	class:has-background-success-light={order.deliveryStatus == "done"}
	class:has-background-link={selected}
>
	<td class="is-family-monospace">
		{#if order.isTakeAway}
			<span class="icon is-size-6">
				<ion-icon name="walk" />
			</span>
		{:else}
			<span class="icon is-size-6 has-text-grey">
				<ion-icon name="restaurant" />
			</span>
		{/if}
		{deliveryWindow.timeSlots[order.timeSlotIndex].time}
	</td>
	<td class="is-size-6">
		{order.customerName} ({order.customerPhoneNr})
	</td>
	<td class="is-size-6">
		<strong>{order.price} kr</strong>
	</td>
	<td class="is-family-monospace is-size-6">
		{order.id}
		{#if order.deliveryStatus == "aborted"}
			<br /><i class="has-text-danger-dark">Avbruten</i>
		{:else if order.deliveryStatus == "done"}
			<br /><i class="has-text-success-dark">Klar!</i>
		{/if}
	</td>
	<td rowspan={2}>
		<div class="notification">
			{#each order.orderRows as orderRow}
				<OrderedProductText item={orderRow} />
				<br />
			{/each}
		</div>
	</td>
</tr>

<tr
	class:has-background-danger-light={order.deliveryStatus == "aborted"}
	class:has-background-success-light={order.deliveryStatus == "done"}
	class:has-background-link={selected}
>
	<td colspan={3} class="has-text-right">
		<button
			class="button is-outlined has-text-left"
			class:is-small={!order.administratorNotes}
			class:is-rounded={!order.administratorNotes}
			class:is-fullwidth={order.administratorNotes}
			style="text-align: left; justify-content: left;"
			class:has-text-gray={!order.administratorNotes ||
				!order.administratorNotes.length}
			class:has-text-semibold={order.administratorNotes?.length}
			on:click={handleNotes(order)}
			>{order.administratorNotes || "Anteckna..."}</button
		>
	</td>
	<td colspan={1}>
		{#if order.deliveryStatus != "aborted" && order.deliveryStatus != "done"}
			{#if order.deliveryStatus == "submitted"}
				<button
					class="button is-small is-info is-rounded"
					on:click={handleChangeDeliveryStatus(order, "confirmed")}
					><b>Bekräfta!</b></button
				>
			{:else if order.deliveryStatus == "confirmed"}
				<button
					class="button is-small is-success is-rounded"
					on:click={handleChangeDeliveryStatus(order, "done")}
					><strong>Klar!</strong></button
				>
			{/if}
			<button
				class="button is-small is-danger is-rounded"
				on:click={handleChangeDeliveryStatus(order, "aborted")}
				><strong>Avbryt</strong></button
			>
			<!-- TODO: orders are always confirmed {#if order.deliveryStatus != "submitted"}
				<button
					transition:blur
					class="button is-small is-outlined is-rounded"
					on:click={handleChangeDeliveryStatus(
						order,
						"submitted"
					)}>återställ</button
				>
			{/if} -->
		{/if}
	</td>
</tr>

<style>
	td {
		border: none;
	}
	.top-row {
		border-top: 1px solid lightgray;
	}
</style>
