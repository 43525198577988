<script lang="ts">
	import type { DeliveryWindow, OrderedProductRow } from "~/api";
	import { selectedOrder } from "~/stores/administration";
	import OrderedProductText from "~/components/content/OrderedProductText.svelte";

	// Given Delivery Window
	export let deliveryWindow: DeliveryWindow;

	// Given Order Item
	export let item: OrderedProductRow;

	// The Order of the given item
	$: order = deliveryWindow.orders.find((order) => order.id == item.orderId);

	// Is this a part of the currently selected order?
	$: selected = !!order && !!$selectedOrder && order.id == $selectedOrder.id;

	// UI

	// Text class name
	$: textClass = selected ? "is-link" : "is-info"

</script>

<div class="columns is-multiline">
	<div class="column is-one-third has-text-right">
		{#if order}
			<span
				class="tag is-link is-family-monospace has-text-weight-bold has-hover-effect is-clickable" class:is-light={!selected}
				on:click={() => selectedOrder.set(order)}
			>
				{#if order.isTakeAway}
					<span class="icon is-size-6">
						<ion-icon name="walk" />
					</span>
				{:else}
					<span class="icon is-size-6 has-text-link">
						<ion-icon name="restaurant" />
					</span>
				{/if}
				&nbsp;
				{order.customerName}: {order.id}
				{deliveryWindow.timeSlots[order.timeSlotIndex].time}
			</span>
		{/if}
	</div>
	<div class="column is-two-thirds">
		<span class={`tag is-medium is-light ${textClass} is-fullwidth`}>
			<OrderedProductText {item} showNotes bold />
		</span>
	</div>
</div>
