<script lang="ts">
	import type { PromptDocument } from "../../../../shared";
	import Prompt from "../../content/Prompt.svelte";
	import { onMount } from "svelte/internal";
	import { blur } from "svelte/transition";
	import { gotoRoute } from "~/stores";
	import { prompt } from "~/stores/prompt";

	// The data to work with, with any default values
	$: data = $prompt || {
		title: "",
		body: "",
		dark: false,
		important: true,
		visible: false,
	};

	// Event handlers

	// Save the data
	const onSave = async () => {
		$prompt = data;
		gotoRoute("start");
	};
</script>

<h1 class="title">PROMPTER</h1>
<h2 class="subtitle">
	Välj att visa gömma en informations-prompt när kunderna först besöker sidan
</h2>
<div class="box" in:blur>
	<div class="field">
		<label for="name" class="title">Titel</label>
		<div class="control">
			<input
				id="title"
				placeholder="👻 Obs: Idag är pizzerian hemsökt"
				class="input is-medium"
				type="text"
				bind:value={data.title}
			/>
		</div>
	</div>

	<div class="field">
		<label for="body" class="label">Text</label>
		<div class="control">
			<textarea id="body" class="textarea" bind:value={data.body} />
		</div>
	</div>

	<div class="field" on:click={() => (data.dark = !data.dark)}>
		<div class="control">
			<input
				id="dark"
				class="checkbox"
				type="checkbox"
				bind:checked={data.dark}
			/>
			Mörkt tema
		</div>
	</div>

	<div class="field" on:click={() => (data.important = !data.important)}>
		<div class="control">
			<input
				id="important"
				class="checkbox"
				type="checkbox"
				bind:checked={data.important}
			/>
			Viktigt meddelande
		</div>
		<p class="help">
			Prompten kommer visas öppnad när kunden först öppnar
			bokniningssystemet, och dölja allt innehåll tills kunden fortsätter
		</p>
	</div>

	

	<div class="level mt-6">
		<div class="level-left">
			<div class="level-item">
				<div class="field">
					<div class="control">
						<div id="visible" class="select is-rounded is-medium">
							<select bind:value={data.visible}>
								<option value={true}>Visas för kunder</option>
								<option value={false}>Är gömd tillsvidare...</option>
							</select>
						</div>
					</div>
				</div> 
			</div>
		</div>
		<div class="level-left">
			<div class="level-item">
				<button class="button is-link is-medium" on:click={onSave}
					>Spara!</button
				>
			</div>
		</div>
	</div>
</div>

<h3 class="title is-4 pt-4">Förhandsvisning</h3>
<Prompt visible {data} />
