<script lang="ts">
	import { blur } from "svelte/transition";
	import { createLocalStore } from "~/stores";
	
	import P5Canvas from "./P5Canvas.svelte";
	import {
		createGame,
		maxPoints,
		points,
		tries,
		alltimeHighscore,
		sessionHighscore
	} from "./AlmostPong";

	export let playerName = createLocalStore("almost-pong-name", "");
	export let fullscreen = false;
</script>

<div
	class="notification has-background-dark has-text-white pl-0 pr-0"
	in:blur={fullscreen ? undefined : { delay: 1000, duration: 2000 }}
>
	<div class="level pl-2 pr-2">
		<div class="level-left">
			<div class="level-item">
				<h1 class="title is-3">PONG, TYP</h1>
			</div>
		</div>
		<div class="level-right">
			<div class="level-item">
				{#if fullscreen}
					<h1 class="title is-4"><u class="is-clickable" on:click={() => {
						playerName.set("");
					}}>{$playerName}</u>, {$points} poäng</h1>
				{:else}
					<h1 class="title is-4">{$points} poäng</h1>
				{/if}
			</div>
		</div>
	</div>
	<P5Canvas seed={$tries} creator={createGame($playerName)} />
	<div class:p-3={fullscreen}>
		<p>Tryck på skärmen för att spela! Som bäst har du hittills fått {$maxPoints} poäng.</p>
		<br/>
		{#if $sessionHighscore && $sessionHighscore.name}
			<p><b>{$sessionHighscore.name}</b></p>
			<p>Bästa poäng under pågående tävling:</p>
			<ul>
				{#each $sessionHighscore.entries as entry}
				<li>{entry.points} poäng: {entry.name}</li>
				{/each}
			</ul>
			<br/>
		{/if}
		{#if $alltimeHighscore}
			<p>Bästa poäng någonsin:</p>
			<ul>
				{#each $alltimeHighscore.entries as entry}
				<li>{entry.points} poäng: {entry.name}</li>
				{/each}
			</ul>
			<br/>
		{/if}
		<p>
			Spelet <i>Almost Pong</i> skapades ursprungligen av
			<a href="https://www.lessmilk.com" target="blank">Thomas Palef</a>.
		</p>
		{#if !fullscreen}
			<p class="mt-3"><a href="/pong" target="_blank" class="button is-small is-black">Spela i fullskärm</a></p>
		{/if}
	</div>
</div>
