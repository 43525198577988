<script>
	import { route, linkTo } from "~/stores/url";
	import { adminIsSignedIn, signOut } from "~/stores/administration";

	import AdminSignIn from "../components/general/SignIn.svelte";
	import AdminNotifications from "../components/content/AdminNotifications.svelte";

	import ProductsList from "../components/administration/products/ProductList.svelte";
	import ProductEditor from "../components/administration/products/ProductEditor.svelte";

	import DeliveryWindowsList from "../components/administration/deliverywindows/DeliveryWindowsList.svelte";
	import DeliveryWindowsEditor from "../components/administration/deliverywindows/DeliveryWindowEditor.svelte";

	import PromptEditor from "../components/administration/prompt/PromptEditor.svelte";
	import PongEditor from "../components/administration/pong/PongEditor.svelte";

	import DeliveryWindowsOverview from "../components/administration/overview/DeliveryWindowOverview.svelte";
	import OverviewNavigator from "../components/administration/overview/OverviewNavigator.svelte";
</script>

<div class="is-hidden-tablet">
	<div class="notification is-info has-text-centered">
		<p>Admin sidorna är enklare att använda på en större skärm!</p>
	</div>
</div>


<div class="notification is-info">
	<div class="level">
		<div class="level-left">
			<div class="level-item">
				<button
					class="button is-fullwidth is-link"
					class:is-light={$route.name != "admin"}
					on:click={linkTo("admin")}
				>
					<!-- <span class="icon is-small">
						<ion-icon name="pizza-outline" />
					</span> -->
					<span>
						Idag
					</span>
				</button>
			</div>
			<div class="level-item">
			<button
					class="button is-fullwidth is-link"
					class:is-light={$route.name != "admin-list-deliverywindows"}
					on:click={linkTo("admin-list-deliverywindows")}
				>
					<!-- <span class="icon is-small">
						<ion-icon name="pizza-outline" />
					</span> -->
					<span>
						Alla öppetider
					</span>
				</button>
			</div>
			<div class="level-item">
			<button
					class="button is-fullwidth is-link"
					class:is-light={$route.name != "admin-list-products"}
					on:click={linkTo("admin-list-products")}
				>
					<!-- <span class="icon is-small">
						<ion-icon name="pizza-outline" />
					</span> -->
					<span>
						Alla produkter
					</span>
				</button>
			</div>
			<div class="level-item">
				<button
					class="button is-fullwidth is-link"
					class:is-light={$route.name != "admin-edit-prompt"}
					on:click={linkTo("admin-edit-prompt")}
				>
					<span>
						Prompten
					</span>
				</button>
			</div>
			<div class="level-item">
				<button
					class="button is-fullwidth is-link"
					class:is-light={$route.name != "admin-edit-pong"}
					on:click={linkTo("admin-edit-pong")}
				>
					<span>
						Pong
					</span>
				</button>
			</div>
		</div>
		{#if adminIsSignedIn}
		<div class="level-right is-hidden-mobile">
			<div class="level-item">
				<button
					class="button is-danger"
					on:click={() => signOut()}
				>
						<!-- <span class="icon is-small"
							><ion-icon name="home" />&nbsp;</span
						> -->
						<span class="">Logga ut</span>
				</button>
			</div>
		</div>
		{/if}
	</div>
</div>

{#if !$adminIsSignedIn}
	<AdminSignIn />
{:else}
	<div class="container">
		<AdminNotifications/>
		<section class="section">
			{#if $route.name == "admin-list-products"}
				<ProductsList />
			{:else if $route.name == "admin-edit-product"}
				<ProductEditor id={$route.params.id} />
			{:else if $route.name == "admin-new-product"}
				<ProductEditor id={undefined} />
			{:else if $route.name == "admin-list-deliverywindows"}
				<DeliveryWindowsList />
			{:else if $route.name == "admin-view-deliverywindow"}
				<DeliveryWindowsOverview id={$route.params.id} />
			{:else if $route.name == "admin-edit-deliverywindow"}
				<DeliveryWindowsEditor id={$route.params.id} />
			{:else if $route.name == "admin-new-deliverywindow"}
				<DeliveryWindowsEditor />
			{:else if $route.name == "admin-edit-prompt"}
				<PromptEditor/>
			{:else if $route.name == "admin-edit-pong"}
				<PongEditor/>
			{:else}
				<OverviewNavigator />
			{/if}
		</section>
	</div>
{/if}
