<script lang="ts">
	import { createAPIStore, linkTo } from "~/stores";
	import { blur } from "svelte/transition";
	import DeliveryWindowListItem from "./DeliveryWindowListItem.svelte";

	// Component settings
	const nrOfMostRecentToShow: number = 10;

	// Data Sources
	let allDeliveryWindows = createAPIStore("deliverywindows");

	// State
	let onlyShowMostRecent: boolean = true;

	$: nrOfItemsToShow = onlyShowMostRecent
		? Math.min($allDeliveryWindows?.length || 0, nrOfMostRecentToShow)
		: $allDeliveryWindows?.length || 0;
	$: showFilterButton =
		($allDeliveryWindows?.length || 0) > nrOfMostRecentToShow;

	// List to show
	$: deliveryWindows = $allDeliveryWindows
		? $allDeliveryWindows
				.sort((a, b) => {
					if (a.day > b.day) {
						return -1;
					}
					if (b.day > a.day) {
						return 1;
					}
					return 0;
				})
				.filter((_, index) => index < nrOfItemsToShow)
		: [];
</script>

<h1 class="title">
	Öppetider (dagar)
</h1>
<h2 class="subtitle">
	Klicka på en rad för att visa den dagen i <b>översikt</b>.
	<br />
	använd knapparna för att styra dagens synlighet eller rediga leveranstider.
	<br /><br />
	<i
		>Det går inte att ändra dagar med beställningar, men de kan gömmas för
		kunder.</i
	>
</h2>
<div class="box" in:blur>
	<table class="table is-hoverable is-fullwidth is-striped">
		<tbody>
			<tr>
				<th>Datum</th>
				<th>Beställningar</th>
				<th>Max behövda degar</th>
				<th>Synlig för kunder</th>
				<th />
			</tr>
		</tbody>
		<tbody>
			{#each deliveryWindows as deliveryWindow}
				<DeliveryWindowListItem item={deliveryWindow} />
			{/each}
		</tbody>
	</table>
	<div class="block has-text-right mt-6">
		{#if showFilterButton}
			<button
				class="button is-light is-rounded"
				on:click={() => (onlyShowMostRecent = !onlyShowMostRecent)}
			>
				{#if onlyShowMostRecent}
					<strong>Visa alla</strong>
				{:else}
					<strong>Visa senaste</strong>
				{/if}
			</button>
		{/if}
		<button
			class="button is-link is-rounded"
			on:click={linkTo("admin-new-deliverywindow")}
			><strong>Lägg till ny dag...</strong></button
		>
	</div>
</div>
