<script lang="ts">
	import type { PromptDocument } from "~/api";
	import { slide, blur } from "svelte/transition";
	import { onMount } from "svelte";

	// Given prompt data
	export let data: PromptDocument;

	// overload the visible status
	export let visible: boolean = false;

	// Indicates that a prompt exists that should be shown
	$: visible = visible || data.visible;

	// Indicates that the prompt should be expanded
	export let showPromptExpanded = false;

	onMount(() => {
		showPromptExpanded = data.important;
	})
</script>

{#if visible}
	<div
		class="notification is-primary p-2 has-text-centered"
		class:has-background-dark={data.dark}
		in:blur={{ duration: 1000 }}
		class:remove-top-padding={!showPromptExpanded}
		class:is-clickable={!showPromptExpanded}
		on:click={() => (showPromptExpanded = true)}
	>
		<div class="is-family-monospace has-text-weight-semibold is-size-4 p-0">
			{data.title}
		</div>
		{#if showPromptExpanded}
			<div in:slide={{ duration: 1000 }}>
				<div class="has-text-left p-5 is-size-5">
					{data.body}
				</div>
				<button
					class="button is-white is-rounded mt-4"
					on:click={(e) => {
						showPromptExpanded = false;
						e.stopPropagation();
					}}>Fortsätt...</button
				>
			</div>
		{/if}
	</div>
{/if}

<style>
	.remove-top-padding {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
</style>
