<script lang="ts">
	import { blur } from "svelte/transition";
	import {
		order,
		deliveryWindow,
		timeSlot,
		isUpcomming,
		now,
	} from "~/stores/order";
	import { gotoRoute } from "~/stores";

	import Presentation from "~/components/content/Presentation.svelte";
	import OrderedProductText from "~/components/content/OrderedProductText.svelte";
	import Game from "~/components/game/Game.svelte";
	import { dwDate, tsDate } from "~/_utils";
</script>

<main class="p-3">
	<div class="container is-widescreen">
		<Presentation />
		<div class="notification is-info has-text-centered">
			{#if !$order || !$deliveryWindow || !$timeSlot}
				<div />
			{:else}
				<div in:blur={{ delay: 800 }}>
					<div
						class="header is-family-monospace has-text-centered is-size-3 is-size-4-mobile mb-2"
					>
						{#if $order.deliveryStatus == "submitted"}
							<span>Tack för din beställning!</span>
						{:else if $order.deliveryStatus == "confirmed"}
							<b>Din beställning är bekräftad! 🍕</b>
						{:else if $order.deliveryStatus == "aborted"}
							<span class="has-text-danger"
								>Din beställning är avbruten 😢</span
							>
						{:else if $order.deliveryStatus == "done"}
							<b>Din order har levererats! 🍕</b>
						{/if}
						{#if $isUpcomming && $order.deliveryStatus != "done" && $order.deliveryStatus != "aborted"}
							<br />
							<span
								>kl. {$timeSlot.time} - {dwDate(
									$deliveryWindow
								).toFormat("DDDD")}</span
							>
							<div
								class="has-text-centered is-size-5 is-size-5-mobile mb-3 is-family-primary has-text-grey"
							>
								{#key $now}
									{tsDate(
										$deliveryWindow,
										$timeSlot
									).toRelativeCalendar({
										locale: "sv",
									})}
								{/key}
							</div>
						{/if}
					</div>

					<div class="has-text-centered is-size-5 is-size-5-mobile mb-3">
						{#if $order.deliveryStatus == "submitted"}
							<span
								>När vi sett din order så bekräftar vid den innan den
								tillagas...</span
							>
						{:else if $order.deliveryStatus == "confirmed" && $order.isTakeAway}
							<span
								>Din beställning är bekräftad, välkommen att hämta dina
								pizzor från oss!</span
							>
						{:else if $order.deliveryStatus == "confirmed"}
							<span
								>Din beställning är bekräftad, välkommen till oss för en
								trevlig matupplevelse!</span
							>
						{:else if $order.deliveryStatus == "aborted"}
							<span
								>Denna beställning blev avbokad av dig eller av oss,
								välkommen att beställa igen!</span
							>
						{:else if $order.deliveryStatus == "done"}
							<span
								>Hoppas det smakade och varmt välkommen tillbaka!</span
							>
						{/if}
					</div>

					<div class="has-text-centered is-size-6 pt-5">
						<button
							class="button is-warning is-rounded is-light has-hover-effect"
							on:click={() =>
								window.open &&
								window.open(window.location.href, "_blank")}
							><ion-icon name="share-outline" />&nbsp;Öppna beställningen
							i en ny flik</button
						>
						<br />
						<br />
						<span class="has-text-info-dark"
							>Vi skickar inga mail, den här sidan fungerar som din
							bokningsbekräftelse</span
						>
					</div>
					<div class="divider">Beställningsdetaljer</div>

					<div class="columns">
						<div class="column has-text-left">
							<div class="box">
								<p class="is-size-4 mb-1">
									<strong>Pris:</strong>
									{$order.price} kr
								</p>
								{#if $order.deliveryStatus == "submitted" || $order.deliveryStatus == "confirmed"}
									<p class="is-size-7 mb-3 has-text-italic">
										Betalning görs på plats när du hämtar din order
									</p>
								{/if}
								<p class="is-size-6 mb-3">
									<strong
										><ion-icon
											name="bookmark"
										/>&nbsp;Beställningsnummer:</strong
									>&nbsp;
									<span
										class="tag is-medium is-link is-light is-family-monospace"
										>{$order.id}</span
									>
								</p>
								<p class="is-size-6 mb-3">
									<strong><ion-icon name="time" />&nbsp;När:</strong
									>&nbsp;<span
										class="tag is-medium is-success is-light"
										>{$deliveryWindow.day}&nbsp;{$timeSlot?.time}</span
									>
								</p>
								<p class="is-size-6 mb-2">
									<strong
										><ion-icon name="person" />&nbsp;Ditt namn:</strong
									>&nbsp;{$order.customerName}
								</p>
								<p class="is-size-6 mb-2">
									<strong
										><ion-icon
											name="call"
										/>&nbsp;Telefonnummer:</strong
									>&nbsp;{$order.customerPhoneNr}
								</p>
							</div>
						</div>
						<div class="column has-text-left">
							<p class="is-size-6 mb-2">
								<strong
									><ion-icon name="person" />&nbsp;Dina pizzor:</strong
								>
							</p>
							{#each $order.orderRows as productRow}
								<span class="is-size-4">
									<OrderedProductText item={productRow} showNotes />
									<br />
								</span>
							{/each}
						</div>
					</div>
				</div>
			{/if}
			<div class="has-text-centered mt-4" in:blur={{ delay: 2000 }}>
				<button
					class="button is-info is-rounded is-light is-outlined has-hover-effect"
					on:click={() => gotoRoute("start", {}, { reload: true })}
					><ion-icon name="return-down-back" />&nbsp;Gör en ny beställning</button
				>
			</div>
			{#if $order && $deliveryWindow}
				<p class="pt-6 pb-4" in:blur={{ delay: 0, duration: 2000 }}>
					Medans du väntar kanske du vill spela ett spel?
				</p>
				<Game />
			{/if}
		</div>
	</div>
</main>
