import { writable, derived, get, readable } from "svelte/store";
import type {
	OrderedProductRow,
	DeliveryWindow,
	Product,
	OrderDocument,
} from "~/api";
import { createShortId, createDocument } from "~/api";
import { createAPIStore, createDerivedAPIStore } from "./_apiStore";
import type { DateTime } from "luxon";

// The booking process uses the customer information a lot
import {
	customerName,
	customerPhoneNr,
	customerInformationIsValid,
} from "~/stores/customerInfo";
import { gotoRoute } from ".";
import { currentDateTime, queueItemIsSelectable } from "~/_utils";

type BookingPages =
	| "products"
	| "deliverywindows"
	| "checkout"
	| "info"
	| "menu";

interface VirtualOrder
	extends Omit<
		OrderDocument,
		"id" | "deliveryStatus" | "isPayed" | "customerName" | "customerPhoneNr"
	> {
	price: number;
}

// Data ---------------------------------------------------------------------------------------------------------------

// All delivery windows
export const allDeliveryWindows = createAPIStore("deliverywindows");

// All products
export const allProducts = createAPIStore("products");

// Available Delivery Windows
export const deliveryWindows = derived(
	allDeliveryWindows,
	($allDeliveryWindows) => $allDeliveryWindows.filter((order) => order.visible)
);

// The current DateTime, updated every minute
export const now = readable<DateTime>(currentDateTime(), (set) => {
	// Define how to update
	const update = () => set(currentDateTime());

	// Set initial value
	update();

	// Update atleast every minute
	const interval = setInterval(() => update(), 30000);

	// Handle unsubscribition
	return () => clearInterval(interval);
});

// Selections ---------------------------------------------------------------------------------------------------------

/** The products the customer wants to order */
export const productRows = writable<Array<OrderedProductRow>>([]);

/** The different products that currently exists in the cart */
export const addedProducts = derived(
	[productRows, allProducts],
	([$productRows, $allProducts]) =>
		$productRows
			.map((row) => row.productId)
			.filter((id, index, self) => self.indexOf(id) === index)
			.map((id) => $allProducts.find((p) => p.id == id))
			.filter((product) => product != undefined) as Array<Product>
);

/** The currently selected number of products the customer wants to order, minimum 1 */
export const numberOfWantedProducts = derived(productRows, ($productRows) =>
	Math.max(1, $productRows.length)
);

/** The currently selected delivery window id */
export const deliveryWindowId = writable<DeliveryWindow["id"] | undefined>(
	undefined
);

/** The currently selected delivery window id */
export const deliveryWindow = createDerivedAPIStore(
	deliveryWindowId,
	"deliverywindows"
);

export const deliveryWindowHasSuboptimalTimes = derived(
	[deliveryWindow, numberOfWantedProducts],
	([$deliveryWindow, $numberOfWantedProducts]) => {
		if ($deliveryWindow) {
			for (const item of $deliveryWindow.manufacturingQueue) {
				if (item.availableProductDeliveries < $numberOfWantedProducts) {
					return true;
				}
			}
		}
		return false;
	}
);

/** The currently selected time slot index of the selected delivery window */
const timeSlotIndex = writable<number>(-1);

/** Indicates that the booking is for Take Away or not */
const isTakeAway = writable<boolean>(false);

/** The currently selected time slot  */
export const timeSlot = derived(
	[deliveryWindow, timeSlotIndex],
	([$deliveryWindow, $index]) => {
		if ($deliveryWindow && $index >= 0) {
			return $deliveryWindow.timeSlots[$index];
		}
		return undefined;
	}
);

/** The currently selected manufacturing que item  */
export const manufacturingQueueItem = derived(
	[deliveryWindow, timeSlotIndex],
	([$deliveryWindow, $index]) => {
		if ($deliveryWindow && $index >= 0) {
			return $deliveryWindow.manufacturingQueue[$index];
		}
		return undefined;
	}
);

// Status checks ------------------------------------------------------------------------------------------------------

/** There are any delivery windows available */
export const deliveryWindowsExists = derived(
	deliveryWindows,
	($deliveryWindows) => !!$deliveryWindows?.length
);

/** Indicates that any products has been added  */
export const hasAddedProducts = derived(
	productRows,
	($productRows) => $productRows.length > 0
);

/** Indicates that new products can not be added  */
export const unableToAddProducts = derived([productRows], ([$productRows]) => {
	return $productRows.length >= 6;
});

/** Indicates that adding new products will make the selected time slot invalid  */
export const unableToFitAdditionalProducts = derived(
	[manufacturingQueueItem, productRows, isTakeAway, timeSlot],
	([$manufacturingQueueItem, $productRows, $isTakeAway, $timeSlot]) => {
		if ($timeSlot) {
			if ($isTakeAway) {
				return (
					$manufacturingQueueItem &&
					$manufacturingQueueItem.maxNumberOfProductsAvailableForTakeAway <
						$productRows.length + 1
				);
			}
			return (
				$manufacturingQueueItem &&
				$manufacturingQueueItem.maxNumberOfProductsAvailableForResturant <
					$productRows.length + 1
			);
		}
		// No time slot selected, any number of products can fit
		return false;
	}
);

/** Indicates that the selected time slot is suboptimal for the number of products */
export const suboptimalTimeSelected = derived(
	[manufacturingQueueItem, numberOfWantedProducts],
	([$manufacturingQueueItem, $numberOfWantedProducts]) => {
		return (
			$manufacturingQueueItem &&
			$manufacturingQueueItem.availableProductDeliveries <
				$numberOfWantedProducts
		);
	}
);

/** Indicates that the selected time slot is invalid for the number of products */
export const invalidTimeSelected = derived(
	[manufacturingQueueItem, numberOfWantedProducts, isTakeAway],
	([$manufacturingQueueItem, $numberOfWantedProducts, $isTakeAway]) => {
		if ($manufacturingQueueItem) {
			return !queueItemIsSelectable(
				$manufacturingQueueItem,
				$isTakeAway,
				$numberOfWantedProducts
			);
		}
		// No time slot (queue item) selected, not invalid
		return false;
	}
);

// --------------------------------------------------------------------------------------------------------------------

/** The virtual order TODO: something */
export const virtualOrder = derived(
	[
		productRows,
		deliveryWindow,
		timeSlotIndex,
		isTakeAway,
		manufacturingQueueItem,
	],
	(derived) => {
		const [
			$productRows,
			$deliveryWindow,
			$timeSlotIndex,
			$isTakeAway,
			$manufacturingQueueItem,
		] = derived;

		if ($deliveryWindow) {
			const vo: VirtualOrder = {
				deliveryWindowId: $deliveryWindow.id,
				timeSlotIndex: $timeSlotIndex,
				isTakeAway: $isTakeAway,
				orderRows: $productRows,
				numberOfWantedProducts: $productRows.length,
				price: $productRows.reduce(
					(price, product) => price + product.price,
					0
				),
			};
			return vo;
		}
		return undefined;
	}
);

// TODO: Move to UI ----------------------------------

export const selectedBookingPage = writable<BookingPages>("menu");

export const mostAppropiateBookingPage = derived(
	[timeSlot, productRows],
	([$timeSlot, $productRows]) => {
		let val: BookingPages = "menu";

		if ($timeSlot && $productRows.length) {
			val = "checkout";
		} else if ($timeSlot) {
			val = "products";
		} else if ($productRows.length) {
			val = "deliverywindows";
		}
		return val;
	}
);

// Actions ------------------------------------------------------------------------------------------------------------

export const setMostAppropiateBookingPage = async () => {
	const nextPage = get(mostAppropiateBookingPage);
	selectedBookingPage.set(nextPage);
};

export const selectDeliveryWindow = async (selection?: DeliveryWindow) => {
	deliveryWindowId.set(selection ? selection.id : undefined);
	if (!selection) {
		timeSlotIndex.set(-1);
	}
};

export const selectTimeSlot = async (index: number, takeAway: boolean) => {
	timeSlotIndex.set(index);
	isTakeAway.set(takeAway);
	setMostAppropiateBookingPage();
};

export const addProduct = async (
	product: Product,
	price: number,
	notes: string = "",
	isGlutenFree: boolean = false
) => {
	const newRow: OrderedProductRow = {
		id: createShortId(),
		name: product.name,
		notes,
		price: price,
		productId: product.id,
		isVegan: product.isVegan,
		isGlutenfree: isGlutenFree,
	};

	const $productRows = get(productRows);
	productRows.set([...$productRows, newRow]);
};

export const removeProduct = async (removedRow: OrderedProductRow) => {
	const $productRows = get(productRows);
	const newRows = $productRows.filter((row) => row.id != removedRow.id);
	productRows.set(newRows);
	if (!newRows.length) {
		setMostAppropiateBookingPage();
	}
};

export const confirmOrder = async () => {
	try {
		const $customerInformationIsValid = get(customerInformationIsValid);
		const $virtualOrder = get(virtualOrder);
		const $invalidTimeSelected = get(invalidTimeSelected);

		if ($invalidTimeSelected) {
			alert("Beställningen kan inte göras till den valda tiden.");
			return;
		}
		if (!$customerInformationIsValid) {
			alert("Den angivna kontaktinformation stämmer inte.");
			return;
		}
		if (!$virtualOrder) {
			alert("Tekniskt fel: orderinformation saknas");
			return;
		}

		const $customerName = get(customerName);
		const $customerPhoneNr = get(customerPhoneNr) as string;


		const newOrder = await createDocument("orders", {
			deliveryWindowId: $virtualOrder.deliveryWindowId,
			timeSlotIndex: $virtualOrder.timeSlotIndex,
			orderRows: $virtualOrder.orderRows,
			numberOfWantedProducts: $virtualOrder.numberOfWantedProducts,
			isTakeAway: $virtualOrder.isTakeAway,
			customerName: $customerName!,
			customerPhoneNr: $customerPhoneNr,
			deliveryStatus: "confirmed", // NOTE: orders are right now confirmed when submitted
			isPayed: false,
		});
		
		// Set player name
		window.localStorage.setItem("almost-pong-name", `"${$customerName}"`);

		// Go to order overview
		gotoRoute("order", { orderId: newOrder.id });
	} catch (err) {
		alert("Ett okänt tekniskt fel inträffade. Försök igen!");
		console.error(err);
	}
};
