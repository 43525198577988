import { derived, writable } from "svelte/store";
import type { Order } from "~/api";
import { createLocalStore } from "./_localStore";
import { enableNotifications } from "./notifications";

// TODO: this is very very unsafe
const users: Record<string, string> = {
  admin: "bananpizzaärgott",
};

/**Local storage tracker for signed-in status */
const adminHasPerformedSignIn = createLocalStore<boolean>(
  "admin-active",
  false
);

/**The admin username stored in local storage */
export const adminUsername = createLocalStore<string | undefined>(
  "admin-name",
  undefined
);

/** The admin password stored in local storage */
export const adminPassword = createLocalStore<string | undefined>(
  "admin-password",
  undefined
);

/** Indicates that the given admin information is valid */
export const adminInformationIsValid = derived(
  [adminUsername, adminPassword],
  ([$adminUsername, $adminPassword]) => {
    if ($adminUsername && $adminUsername.length) {
      return users[$adminUsername] == $adminPassword;
    }
    return false;
  }
);

/** Indicates that the admin has signed in */
export const adminIsSignedIn = derived(
  [adminInformationIsValid, adminHasPerformedSignIn],
  ([$adminInformationIsValid, $adminHasPerformedSignIn]) => {
    return $adminInformationIsValid && $adminHasPerformedSignIn;
  }
);

/** Sign in as administrator */
export function signIn() {
  enableNotifications();
  adminHasPerformedSignIn.set(true);
}

/** Sign out as administrator */
export function signOut() {
  adminPassword.set(undefined);
  adminUsername.set(undefined);
  adminHasPerformedSignIn.set(false);
}

/** Any selected order in the delivery window overview */
export const selectedOrder = writable<Order | undefined>(undefined);

// These stores are used to control what database objects to listen to

//const deliveryWindowId = writable<DeliveryWindow['id'] | undefined>(undefined);
// const orderId = createLocalStore<Order['id'] | undefined>('order-id', undefined); // The order id is stored localy for restoring any aborted ordering process

/** Indicates that the booking process has started */
// export const bookingIsStarted = writable<boolean>(false);

/** The currently selected number of products the customer wants to order */
// export const numberOfWantedProducts = writable<number>(0);

/** The currently selected delivery window */
//export const deliveryWindow = createDerivedAPIStore(deliveryWindowId, 'deliverywindows');

/** The currently selected order */
// export const order = createDerivedAPIStore(orderId, 'orders');

/** Stores the number of products that can be added to an order from the selected time slot  */
// export const availableToAdd = derived(order, order => {
// 	return order ? order.orderRows.length < order.numberOfWantedProducts : 0 // TODO: (timeSlot ? timeSlot.availableProductDeliveries : 0);
// });

// /** Indicates that products can be added to the currently selected order  */
// export const canAddProducts = derived([deliveryWindow, order, availableToAdd], ([deliveryWindow, order, availableToAdd]) => {
// 	return !!(deliveryWindow && order && availableToAdd);
// });

// /** The status of the booking process from started (0) to completed (100) */
// export const bookingProgress = derived(
// 	[bookingIsStarted, deliveryWindow, order, customerInformationIsValid, cookiesAllowed],
// 	([$bookingIsStarted, $deliveryWindow, $order, $customerInformationIsValid, $cookiesAllowed]) => {

// 		if (!$bookingIsStarted) {
// 			return 0;
// 		}
// 		if (!$cookiesAllowed) {
// 			return 5;
// 		}
// 		if (!$deliveryWindow) {
// 			return 30;
// 		}
// 		if (!$customerInformationIsValid) {
// 			return 60;
// 		}
// 		if (!$order) {
// 			return 60;
// 		}
// 		if (!$order.orderRows.length) {
// 			return 70;
// 		}
// 		return 80;
// 	});

// let timeOutId : number | undefined = undefined;

// /**
//  * The booking state is valid when all three main objects
//  * exists and are correctly synced. That is the selected time slot
//  * is the same as the order, and the deliveryWindow is the one
//  * the time slot belongs to
//  */
// export const bookingProcessIsValid = derived(
// 	[deliveryWindow, order, customerInformationIsValid, numberOfWantedProducts],
// 	([$deliveryWindow, $order, $customerInformationIsValid, $numberOfWantedProducts], setValidState) => {

// 		const unset = () => {
// 			if (timeOutId) {
// 				clearTimeout(timeOutId);
// 				timeOutId = undefined;
// 			}
// 		}
// 		const set = (value: boolean, speed?: number, action?: () => Promise<void> | void) => {
// 			setValidState(value);
// 			timeOutId = setTimeout(() => {
// 				action && action();
// 			}, speed || 0);
// 			return unset;
// 		}

// 		//Make sure that the wanted number of products matches any selected order
// 		if (!!$order && (!$numberOfWantedProducts || $numberOfWantedProducts != $order.numberOfWantedProducts)) {
// 			numberOfWantedProducts.set($order.numberOfWantedProducts);
// 			return set(false);
// 		}

// 		// Make sure that the selected delivery window matches any selected order
// 		if (!!$order && (!$deliveryWindow || $deliveryWindow.id != $order.deliveryWindowId)) {
// 			deliveryWindowId.set($order.deliveryWindowId);
// 			return set(false);
// 		}

// 		// If everything is in place but no order exists, create it ~ but debounce for a long
// 		// time so that further state updates has the time to take precedence.
// 		if (!!$deliveryWindow && !$order && $customerInformationIsValid) {
// 			return set(false, 500, () => {
// 				console.log("[BOOKING] A new order will be created");
// 				// UI: Make sure the sheet is expanded when a new order is created if
// 				// the "tutorial" has not been completed
// 				if (!get(oneProductHasBeenAdded)) {
// 					sheetIsExpanded.set(true);
// 				} else {
// 					// Otherwise let the user more quickly access the available products
// 					sheetIsExpanded.set(false);
// 				}

// 				createOrder($deliveryWindow.id);
// 			});
// 		}

// 		// If data exists and is valid then the booking must have started
// 		if (!!$order && !!$deliveryWindow) {
// 			bookingIsStarted.set(true);
// 		}

// 		// Everythings is ok!
// 		return set(true);
// 	});

// export const selectDeliveryWindow = async (selection?: DeliveryWindow) => {
// 	deliveryWindowId.set(selection?.id);
// }

// export const selectOrder = async (selection: Order | undefined) => {
// 	orderId.set(selection?.id);
// 	deliveryWindowId.set(selection?.deliveryWindowId);
// }

// export const createOrder = async (deliveryWindowId: DeliveryWindow['id']) => {
// 	try {
// 		const $numberOfWantedProducts = get(numberOfWantedProducts);
// 		const $customerInformationIsValid = get(customerInformationIsValid);
// 		const $customerName = get(customerName);
// 		const $customerPhoneNr = get(customerPhoneNr);

// 		if (!$customerInformationIsValid) {
// 			throw "Ingen kundinformation är angiven";
// 		}

// 		const newOrder = await createDocument('orders', {
// 			deliveryWindowId: deliveryWindowId,
// 			timeSlotIndex: -1,
// 			orderRows: [],
// 			deliveryStatus: "temporary",
// 			isPayed: false,
// 			isTakeAway: false,
// 			customerName: $customerName!,
// 			customerPhoneNr: $customerPhoneNr!,
// 			numberOfWantedProducts: $numberOfWantedProducts
// 		});
// 		await selectOrder(newOrder);
// 	}
// 	catch (err) {
// 		console.error("Failed to create an order", err);
// 	}
// }

// export const addProductToOrder = async (product: Product, notes: string = "") => {
// 	try {
// 		const $order = get(order);

// 		if (!$order) {
// 			throw "No selected order"
// 		}

// 		const newRow : OrderedProductRow = {
// 			id: createShortId(),
// 			name: product.name,
// 			notes,
// 			price: product.price,
// 			productId: product.id,
// 			orderId: $order.id
// 		};

// 		await modifyDocument('orders', $order.id, {
// 			orderRows: [...$order.orderRows, newRow]
// 		});

// 		// A product has now been added
// 		oneProductHasBeenAdded.set(true);
// 	}
// 	catch (err) {
// 		console.error("Failed to add product to order", err);
// 	}
// }

// export const removeProductFromOrder = async (removedRow: OrderedProductRow) => {
// 	try {
// 		const $order = get(order);

// 		if (!$order) {
// 			throw "No selected order"
// 		}

// 		const orderRows = $order.orderRows.filter((row) => row.id != removedRow.id);

// 		await modifyDocument("orders", $order.id, {
// 			orderRows: [...orderRows],
// 		});
// 	} catch (err) {
// 		console.error("Failed to remove product from order", err);
// 	}
// };

// export const selectTimeSlotForOrder = async (deliveryWindow: DeliveryWindow, index: number) => {
// 	try {
// 		const $order = get(order);

// 		if (!$order) {
// 			throw "No selected order"
// 		}

// 		await modifyDocument('orders', $order.id, {
// 			timeSlotIndex: index
// 		});

// 		// A product has now been added
// 		oneProductHasBeenAdded.set(true);
// 	}
// 	catch (err) {
// 		console.error("Failed to select time slot index", err);
// 	}
// }

// export const cancelOrder = async () => {

// 	try {
// 		const currentOrder = get(order);

// 		if (currentOrder) {
// 			if (!currentOrder.orderRows.length || confirm('Är du säker på att vill avbryta din beställning?')) {
// 				const updatedOrder = await deleteDocument('orders', currentOrder.id);
// 				await selectOrder(updatedOrder);
// 			}
// 		}

// 		// Reload the window
// 		location.reload();
// 	}
// 	catch (err) {
// 		console.error("Failed to cancel the order", err);
// 	}
// }

// export const confirmCustomerInformation = async () => {
// 	try {
// 		const $order = get(order);
// 		const $customerInformationIsConfirmable = get(customerInformationIsConfirmable);
// 		if (!$customerInformationIsConfirmable) {
// 			throw "The customer information is not confirmable";
// 		}

// 		// Modify the current order...
// 		if ($order) {
// 			const $customerName = get(customerName);
// 			const $customerPhoneNr = get(customerPhoneNr);
// 			await modifyDocument("orders", $order.id, {
// 				customerName: $customerName,
// 				customerPhoneNr: $customerPhoneNr
// 			});
// 		}
// 		// Validate the customer information
// 		customerInformationIsConfirmed.set(true);

// 	}
// 	catch (err) {
// 		console.error("Failed to update the customer information", err);
// 		customerInformationIsConfirmed.set(false);
// 	}
// };

// // TODO: fix
// // const onSendOrder = async () => {
// // 	try {
// // 		console.log(order);
// // 		order = await modifyDocument("orders", order.id, order);
// // 	} catch (err) {
// // 		console.error(err);
// // 	}
// // };
