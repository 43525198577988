import type {
  CollectionDocuments,
  Document as DefaultDocument,
} from "./definitions";
import type {
  DeliveryWindowDocument,
  OrderDocument,
  ProductDocument,
  OrderedProductRow,
  DeliveryWindowTimeSlot,
} from "./definitions";
import * as JOI from "joi";
import { DateTime } from "luxon";
import phone from "phone";

/** Creates a validation schema from the given type */
function createSchema<Document>(
  schema: Record<keyof Omit<Document, "id">, JOI.Schema>
): JOI.Schema {
  return JOI.object({ id: JOI.string(), ...schema }).options({});
}

/** Available validation types */
export interface Validators {
  // Documents
  products: CollectionDocuments["products"][1];
  deliverywindows: CollectionDocuments["deliverywindows"][1];
  orders: CollectionDocuments["orders"][1];

  // Custom
  customerInfo: { customerName: string; customerPhoneNr: string };
  timeSlotGenerator: {
    productsPerTimeSlot: number;
    distanceBetweenTimeSlots: number;
    firstTime: DeliveryWindowTimeSlot["time"];
    lastTime: DeliveryWindowTimeSlot["time"];
  };
}

/** Validation results */
type ValidationResults<Document> =
  | {
      ok: true;
      document: Document;
      errors: Record<keyof Partial<Document>, string>;
      summary: undefined;
    }
  | {
      ok: false;
      errors: Record<keyof Partial<Document>, string>;
      summary: string;
    };

/** Validates a given document and returns the results from JOI */
export function validateDocument<
  K extends keyof Validators,
  Document extends Validators[K],
  Input extends Partial<Document>
>(collection: K, document: Input): ValidationResults<Document> {
  const schema = ValidationSchemas[collection];
  const { error, value } = schema.validate(document, {
    abortEarly: false,
    allowUnknown: true,
  });

  const mappedErrors: { [key: string]: string } = {};
  if (error) {
    // NOTE: add swedish language support?
    for (const detail of error.details) {
      mappedErrors[detail.path[0]] = detail.message;
    }
  }
  if (error) {
    return { ok: false, errors: mappedErrors as any, summary: error.message };
  }
  return {
    ok: true,
    errors: mappedErrors as any,
    summary: undefined,
    document: document as any,
  };
}

// --------------------------------------------------------------------------------------------------------------------

// Custom validator functions

const validDay: JOI.CustomValidator<string> = (value, helper) => {
  const date = DateTime.fromFormat(value, "yyyy-MM-dd");

  if (!date.isValid) {
    return helper.message({ custom: "Not a valid date, use YYYY-MM-DD" });
  }
  return value;
};

const validTime: JOI.CustomValidator<string> = (value, helper) => {
  const date = DateTime.fromFormat(value, "HH:mm");

  if (!date.isValid) {
    return helper.message({ custom: "Not a valid time, use HH:mm" });
  }
  return value;
};

const validPhone: JOI.CustomValidator<string> = (value, helper) => {
  const testSWE = phone(value, { country: "SWE" });
  const testINT = phone(value, { country: "" });
  if (testSWE.isValid) {
    return testSWE.phoneNumber;
  }
  if (testINT.isValid) {
    return testINT.phoneNumber;
  }
  return helper.message({ custom: "Not a valid phone number" });
};

// Document schemas

const productSchema = createSchema<ProductDocument>({
  name: JOI.string().required().min(3),
  description: JOI.string().required(),
  price: JOI.number().required().min(0),
  preparetionTime: JOI.number().required().min(0),
  availableAsGlutenfree: JOI.boolean().optional().default(true),
  isVegan: JOI.boolean().optional().default(false),
  availableForOrder: JOI.boolean().required(),
  availableForTakeAway: JOI.boolean().required(),
});

const orderSchema = createSchema<OrderDocument>({
  customerName: JOI.string().required().min(1),
  customerPhoneNr: JOI.string().required().min(0),
  deliveryStatus: JOI.string().required().min(0),
  isPayed: JOI.boolean().required(),
  isTakeAway: JOI.boolean().required(),
  deliveryWindowId: JOI.string().required(),
  timeSlotIndex: JOI.number().required().min(-1),
  numberOfWantedProducts: JOI.number().required(),
  orderRows: JOI.array().required(),
});

// const timeSlotSchema = createSchema<TimeSlotDocument>({
// 	deliveryWindowId: JOI.string().required(),
// 	time: JOI.number().required(),
// 	maxProductDeliveries: JOI.number().required().min(1)
// });

const deliveryWindowSchema = createSchema<DeliveryWindowDocument>({
  day: JOI.string().custom(validDay).required(),
  visible: JOI.boolean().optional(),
  timeSlots: JOI.array().min(2).required(),
});

const customerSchema = createSchema<Validators["customerInfo"]>({
  customerName: JOI.string().required().min(0).messages({
    "string.empty": `Vem är du som beställer?`,
    "any.required": "Vem är du som beställer?",
    "string.min": `"a" should have a minimum length of {#limit}`,
  }),
  customerPhoneNr: JOI.string().custom(validPhone).required().messages({
    "any.required": "Du måste lämna oss ett telefonnummer!",
    "string.empty": `Du måste lämna oss ett telefonnummer!`,
    custom: "Ange ett giltligt telefonnummer!",
  }),
});

const timeSlotGenerationSchema = createSchema<Validators["timeSlotGenerator"]>({
  firstTime: JOI.string().custom(validTime).required(),
  lastTime: JOI.string().custom(validTime).required(),
  distanceBetweenTimeSlots: JOI.number().required().min(10).max(60),
  productsPerTimeSlot: JOI.number().required().min(1).max(10),
});

// --------------------------------------------------------------------------------------------------------------------

/**
 * An implemented map between the available data collections and their respective
 * JOI validation schema
 */
export const ValidationSchemas: Record<keyof Validators, JOI.Schema> = {
  products: productSchema,
  deliverywindows: deliveryWindowSchema,
  orders: orderSchema,
  customerInfo: customerSchema,
  timeSlotGenerator: timeSlotGenerationSchema,
};
