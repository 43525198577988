<script lang="ts">
	import type { HighscoreDocument } from "../../../../shared/definitions/highscore";
	import { createSingletonStore } from "~/stores";
	import { blur } from "svelte/transition";
	
	export const sessionHighscore = createSingletonStore("session-highscore");

	// The data to work with, with any default values
	let data = {
		name: "",
		entries: [
			{ name: "ingen", points: 0},
			{ name: "ingen", points: 0},
			{ name: "ingen", points: 0}
		]
	} as HighscoreDocument;

	// Event handlers

	// Save the data
	const onSave = async () => {
		sessionHighscore.set(data);
		// sessionHighscore.update(() => {
		// 	return data;
		// });
	};
</script>

<h1 class="title">PONG</h1>
<h2 class="subtitle">
	Hantera Pong tävlingar
</h2>
<div class="box" in:blur>
	<div class="field">
		<label for="name" class="label">Ange namn på ev. ny tävling</label>
		<div class="control">
			<input
				id="title"
				placeholder={$sessionHighscore?.name || "Kvällens tävling"}
				class="input is-medium"
				type="text"
				bind:value={data.name}
			/>
		</div>
	</div>
	<div class="level-left">
		<div class="level-item">
				{#if data.name && ($sessionHighscore && !$sessionHighscore.name)}
					<button class="button is-link is-medium" on:click={onSave}>
						Starta ny tävling
					</button>
				{:else if !data.name && ($sessionHighscore && $sessionHighscore.name)}
					<button class="button is-danger is-medium" on:click={onSave}>
						Rensa tävlingsresultat och&nbsp;<b>avsluta</b>&nbsp;pågående tävling
					</button>
				{:else if ($sessionHighscore && $sessionHighscore.name && data.name != $sessionHighscore.name)}
					<button class="button is-danger is-medium" on:click={onSave}>
						Rensa tävlingsresultat och&nbsp;<b>byt namn</b>&nbsp;på tävlingen
					</button>
				{/if}
		</div>
	</div>
	<br/>
	<h3 class="subtitle">Förhandsvisning:</h3>
	{#if data && data.name}
		<p><b>{data.name}</b></p>
		<p>Bästa poäng under pågående tävling:</p>
		<ul>
			{#each data.entries as entry}
			<li>{entry.points} poäng: {entry.name}</li>
			{/each}
		</ul>
		<br/>
	{/if}
	<br/>
	<h3 class="subtitle">Nuvarande tävling:</h3>
	{#if $sessionHighscore && $sessionHighscore.name}
		<p><b>{$sessionHighscore.name}</b></p>
		<p>Bästa poäng under pågående tävling:</p>
		<ul>
			{#each $sessionHighscore.entries as entry}
			<li>{entry.points} poäng: {entry.name}</li>
			{/each}
		</ul>
		<br/>
	{/if}
</div>