<script lang="ts">
	import { fly } from "svelte/transition";
	import { adminInformationIsValid, adminPassword, adminUsername, signIn } from "~/stores/administration";
</script>

<section
	class="section"
	in:fly={{ duration: 100, y: -200 }}
	out:fly={{ duration: 100, y: 200 }}
>
	<div class="container">
		<div class="columns is-centered is-vcentered">
			<div class="column is-5 has-text-centered">
				<div class="notification is-info is-dark">
					<div class="field">
						<label for="customerName" class="label">Användarnamn</label>
						<div class="control">
							<input
								type="text"
								id="customerName"
								class="input is-rounded"
								bind:value={$adminUsername}
							/>
						</div>
						<p class="help is-danger" />
					</div>
					<div class="field">
						<label for="customerPhoneNr" class="label">Lösenord</label>
						<div class="control">
							<input
								type="password"
								id="customerPhoneNr"
								class="input is-rounded"
								bind:value={$adminPassword}
							/>
						</div>
						<p class="help is-danger" />
					</div>
		
					<button
						class="button mt-5 is-rounded is-fullwidth is-link has-hover-effect"
						disabled={!$adminInformationIsValid}
						on:click={() => signIn()}>Fortsätt...</button
					>
				</div>
			</div>
		</div>
	</div>
</section>
