<script lang="ts">
	import type {
		DeliveryWindow,
		DeliveryWindowManufacturingQueueItem,
	} from "~/api";
	import { timeSlotIsUpcomming } from "~/_utils";
	import { now } from "~/stores/booking";
	import ManufacturingQueueOrderColumns from "./ManufacturingQueueOrderColumns.svelte";

	// Given Delivery Window
	export let deliveryWindow: DeliveryWindow;

	// Given Manufacturing Queue Item
	export let item: DeliveryWindowManufacturingQueueItem;

	// UI

	// Title
	$: title = item.orderItems.length
		? `${item.orderItems.length} ${
				item.orderItems.length == 1 ? "pizza" : "pizzor"
		  } ska vara ${item.orderItems.length == 1 ? "klar" : "klara"} till ${
				item.timeSlot.time
		  }`
		: `Inga pizzor att tillverka för ${item.timeSlot.time}`;

	// Indicates the the time slot has passed or has nothing to do
	$: notImportant =
		!item.orderItems.length ||
		!timeSlotIsUpcomming(deliveryWindow, item.timeSlot);
</script>

<div
	class="notification is-info"
	class:is-light={notImportant}
	id={item.timeSlot.time}
>
	<h1 class="is-family-monospace is-size-5 mb-5">
		<strong>{title}</strong>
	</h1>
	{#each item.orderItems as orderItem}
		<ManufacturingQueueOrderColumns {deliveryWindow} item={orderItem} />
	{/each}
</div>
