<script>
	// FIXME: map is not working
	// import {
	// 	 LeafletMap,
	// 	 LeafletMarker,
	// 	 LeafletTileLayer,
	// } from "svelte-leaflet";
	import { toLatLng } from "leaflet/src/geo/LatLng.js"; 

	const centerLatLng = toLatLng([57.472857, 13.263325]);
	const minZoom = 10;
	const maxZoom = 20;
	const initialZoom = 15;
	const attribution =  'Kartinformation från <a href="https://www.openstreetmap.org/">OpenStreetMap</a>, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>'

	const markerLatLng = toLatLng([57.472478, 13.267093]);
</script>

<!-- <LeafletMap style="height:30vw; width: 100%; min-height: 200px; max-height: 900px;" center={centerLatLng} zoom={initialZoom}>
	<LeafletTileLayer
		url={'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'}
		options={{
			minZoom,
    		maxZoom,
			attribution
		}}
	/>
	<LeafletMarker latLng={markerLatLng} options={{ draggable: false }}/>
</LeafletMap> -->