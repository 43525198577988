<script lang="ts">
	import type { OrderedProductRow } from "~/api";

	// Given ordered product item
	export let item: OrderedProductRow;

	// Optionaly print the complete product notes
	export let showNotes : boolean = false;

	// Optionaly make bold
	export let bold : boolean = false;

</script>

<span class:has-text-weight-bold={bold}>
	{item.name}&nbsp;
</span>
{#if item.isVegan}
	<span
		class="tag is-success is-small is-family-monospace has-text-weight-bold"
		>V</span
	>
{/if}
{#if item.isGlutenfree}
	<span
		class="tag is-warning is-small is-family-monospace has-text-weight-bold"
		>G</span
	>
{/if}
{#if item.notes.length}
	<span
		class="tag is-info is-small is-family-monospace has-text-weight-bold is-clickable has-hover-effect"
		on:click={() => alert(`Ditt meddelande till oss: "${item.notes}"`)}
	>
		{#if !showNotes}
		<span class="icon"><ion-icon name="chatbox-ellipses-outline" /></span>
		{:else}
			<span>{item.notes}</span>
		{/if}
	</span>
{/if}
