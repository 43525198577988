<script lang="ts">
	import type { OrderedProductRow } from "~/api";
	import { removeProduct } from '~/stores/booking';
	import { blur } from "svelte/transition";

	import OrderedProductText from './OrderedProductText.svelte';

	// Given ordered product item
	export let item : OrderedProductRow
	
	// Smaller
	export let small : boolean = false
	</script>
	
	{#if small}
	<span class="tag is-medium is-primary is-info" in:blur>
		<button
			class="delete is-small"
			on:click={() => removeProduct(item)}
		/>
		<span class="icon is-large"
			><ion-icon name="pizza" />&nbsp;</span
		>
		<OrderedProductText item={item}/>
	</span>
	{:else}
	<span class="tag is-large is-info is-light has-hover-effect" in:blur>
		<button
			class="delete is-small"
			on:click={() => removeProduct(item)}
		/>
		<span class="icon is-large"
			><ion-icon name="pizza" />&nbsp;</span
		>
		<OrderedProductText item={item}/>
	</span>
	{/if}
	<br/>