import type { DeliveryWindow, DeliveryWindowDocument, DeliveryWindowManufacturingQueueItem, DeliveryWindowTimeSlot } from '../shared';
import { DateTime } from 'luxon';

/** Get the current datetime, "now" - can also be used for testing */
export function currentDateTime() : DateTime {
    // /*NOTE: Use this row for testing */ return DateTime.fromFormat("2021-12-17 17:00", "yyyy-MM-dd HH:mm");
    return DateTime.now();
}

/** Get the date time of a given delivery window */
export function dwDate(deliveryWindow: DeliveryWindow) {
    return DateTime.fromFormat(deliveryWindow.day, "yyyy-MM-dd");
}

/** Get the date time of a given delivery window and timeSlot */
export function tsDate(deliveryWindow: DeliveryWindowDocument, timeSlot: DeliveryWindowTimeSlot) {
    return DateTime.fromFormat(`${deliveryWindow.day} ${timeSlot.time}`, "yyyy-MM-dd HH:mm");
}

/** Checks if the given delivery window contains any valid time slots that can be selected */
export function dwValid(deliveryWindow : DeliveryWindow, numberOfWantedProducts : number) {
    for (const item of deliveryWindow.manufacturingQueue) {
        if (item.maxNumberOfProductsAvailableForTakeAway >= numberOfWantedProducts || item.maxNumberOfProductsAvailableForResturant >= numberOfWantedProducts) {
            return true;
        }
    }
	return false;
}

/** Checks if the given delivery window is in the past or is upcomming */
export function dwIsUpcomming(deliveryWindow : DeliveryWindow, now : DateTime) : boolean {
    return DateTime.fromFormat(
        deliveryWindow.day,
        "yyyy-MM-dd"
    ).startOf("day") >= now.startOf("day");
}

/** Checks if the given time slot of a delivery window is in the past or is upcomming */
export function timeSlotIsUpcomming(deliveryWindow : DeliveryWindowDocument, timeSlot: DeliveryWindowTimeSlot, now: DateTime = currentDateTime()) : boolean {
    return tsDate(deliveryWindow, timeSlot).startOf("minute") >= now.startOf("minute");
}

/** Checks that a Time Slot / Manufacturing Queue is selectable or not */
export function queueItemIsSelectable(manufacturingQueueItem: DeliveryWindowManufacturingQueueItem, isTakeAway: boolean, numberOfWantedProducts : number) : boolean {
	if (isTakeAway) {
		return manufacturingQueueItem.maxNumberOfProductsAvailableForTakeAway >= numberOfWantedProducts;
	}
	return manufacturingQueueItem.maxNumberOfProductsAvailableForResturant >= numberOfWantedProducts;
}