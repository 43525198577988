<script>
	import { slide } from 'svelte/transition'
	import imgSrc from "../../assets/images/logo.png";
	import { selectedBookingPage } from "~/stores/booking"
</script>

<div in:slide={{ delay: 200, duration: 1000 }} class="has-text-centered mt-6"  style="height:153px">
	<img src={imgSrc} alt="bröts logotyp" style="height:153px" class="has-hover-effect" on:click={() => ($selectedBookingPage = "info")} />
<!-- <h1 class="title is-1 has-text-centered mt-6">
</h1> -->
<!-- <h2 class="subtitle is-4 has-text-centered">
	🍕 Pizza Napoletana
</h2> -->
<!-- {#if !$bookingIsStarted}
			<div
				class="has-text-grey is-size-6 has-text-centered m-1 p-1 mb-4"
			>
				<span class="icon-text" in:blur out:slide>
					<span class="icon is-hidden-mobile"
						><ion-icon name="play-skip-forward-outline" /></span
					>
					Tryck på&nbsp;&nbsp;<span class="is-family-secondary"
						>Gör en beställning</span
					>&nbsp;nedanförför att göra din beställning!
				</span>
			</div>
		{/if} -->
<!-- <p>Pizzorna bakas i Napolitansk stil i vår vedeldade ugn.</p>
<p>
	Om du vill ha flera pizzor men det inte finns tillräckligt
	med tider lediga, skriv ett meddelande till oss så försöker
	vi lösa det.
</p>
<p>
	Det finns också möjligheten att beställa på plats. Detta i
	mån av tid och hur många pizzadegar som finns kvar.
</p> -->
</div>