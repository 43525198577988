<script lang="ts">
	import { createAPIStore } from "~/stores";
	import { now } from "~/stores/booking";
	import { dwIsUpcomming } from "~/_utils";
	import DeliveryWindowOverview from "./DeliveryWindowOverview.svelte";

	// All Delivery Windows
	let allDeliveryWindows = createAPIStore("deliverywindows");

	// Sorted Delivery Windows
	$: sortedDeliveryWindows = $allDeliveryWindows
		.filter((dw) => dwIsUpcomming(dw, $now))
		.sort((a, b) => {
			if (a.day > b.day) {
				return 1;
			}
			if (b.day > a.day) {
				return -1;
			}
			return 0;
		});

	// Selected index to view
	let index = 0;

	$: selectedDeliveryWindow =
		sortedDeliveryWindows &&
		sortedDeliveryWindows.length &&
		sortedDeliveryWindows[index]
			? sortedDeliveryWindows[index]
			: undefined;
	$: hasNext =
		sortedDeliveryWindows && index + 1 < sortedDeliveryWindows.length;
	$: hasPrev = sortedDeliveryWindows && index - 1 >= 0;
</script>

<h2 class="subtitle is-4">
	<br />
	Välkommen till admin-delen av bokningsystemet! Använd knapparna ovanför för att ändra öppetider och produkter.&nbsp;{#if selectedDeliveryWindow}Här
		nedaför ser du de kommande öppetidererna i översikt:{:else}Just nu finns det inga kommande
		öppetider.{/if}
	{#if selectedDeliveryWindow}
		<br />
		<!-- svelte-ignore a11y-missing-attribute -->
		<span on:click={() => hasPrev && index--} class="is-clickable has-hover-effect has-text-link" class:is-hidden={!hasPrev}
			><span class="icon is-large" 
				><ion-icon name="play-back" /></span
			></span
		>
		<strong>{selectedDeliveryWindow.day} </strong>
		<!-- svelte-ignore a11y-missing-attribute -->
		<span on:click={() => hasNext && index++} class="is-clickable has-hover-effect has-text-link" class:is-hidden={!hasNext}
			><span class="icon is-large"
				><ion-icon name="play-forward" /></span
			></span
		>
	{/if}
</h2>
<hr />
{#if selectedDeliveryWindow}
	{#key index}
		<DeliveryWindowOverview id={selectedDeliveryWindow.id} />
	{/key}
{/if}
