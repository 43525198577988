<script lang="ts">
	import { onMount } from "svelte/internal";
	import { blur } from "svelte/transition";
	import { fetchDocument } from "~/api";
	import { gotoRoute } from "~/stores";

	import { ProductDocument, validateDocument } from "../../../../shared";
	import {
		createDocument,
		deleteDocument,
		modifyDocument,
	} from "../../../api";

	// Given product id or undefined if the product is new
	export let id: string | undefined;

	// Indicates that form is either for editing an existing, or creating a new product
	let isModifying = !!(id && id.length);

	// The data to work with, with any default values
	let data: Partial<ProductDocument> = {
		preparetionTime: 15,
		availableAsGlutenfree: true,
		isVegan: false,
		availableForOrder: true,
		availableForTakeAway: true,
	};

	// Current editor status
	$: validation = validateDocument("products", data);
	$: errors = validation.errors;

	// UI
	$: titleText = isModifying
		? "Ändra produkten " + data.name
		: "Lägg till en ny produkt";
	$: saveText = isModifying ? "Spara ändringar" : "Spara ny produkt";

	// Event handlers

	// Fetch any existing data on mount
	onMount(async () => {
		try {
			const existingData = id
				? await fetchDocument("products", id)
				: undefined;
			if (existingData) {
				data = { ...data, ...existingData };
			} else {
				id = undefined;
				isModifying = false;
			}
		} catch (err) {
			gotoRoute("admin-list-products");
		}
	});

	// Save the data
	const onSave = async () => {
		const validation = validateDocument("products", data);
		if (validation.ok) {
			if (isModifying && id) {
				await modifyDocument("products", id, validation.document);
			} else {
				await createDocument("products", validation.document);
			}
			gotoRoute("admin-list-products");
		}
	};

	// Delete the data from the database
	const onDelete = async () => {
		const isSure = confirm("Är du säker? Produkten går inte att återställa.");
		if (isSure) {
			if (isModifying && id) {
				await deleteDocument("products", id);
				gotoRoute("admin-list-products");
			}
		}
	};
</script>

<h1 class="title">{titleText}</h1>
<h2 class="subtitle">Klicka på en rad för att redigera produkten</h2>
<div class="box" in:blur>
	<div class="field">
		<label for="name" class="label">Produktens namn</label>
		<div class="control">
			<input
				id="name"
				placeholder="En pizza"
				class="input is-medium"
				type="text"
				bind:value={data.name}
			/>
		</div>
		<p class="help is-danger">{errors.name || ""}</p>
	</div>

	<div class="field">
		<label for="description" class="label">Beskrivning</label>
		<div class="control">
			<textarea
				id="description"
				class="textarea"
				bind:value={data.description}
			/>
		</div>
		<p class="help is-danger">{errors.description || ""}</p>
	</div>

	<div class="field">
		<label for="price" class="label">Pris</label>
		<div class="control">
			<input
				id="price"
				class="input"
				type="number"
				placeholder="100"
				bind:value={data.price}
			/>
		</div>
		<p class="help">Produktens pris i <strong>kronor</strong></p>
		<p class="help is-danger">{errors.price || ""}</p>
	</div>

	<!-- TODO: preparation time is disabled <div class="field">
		<label for="prepTime" class="label">Förberedelsetid</label>
		<div class="control">
			<input
				id="prepTime"
				class="input"
				type="number"
				placeholder="30"
				bind:value={data.preparetionTime}
			/>
		</div>
		<p class="help">
			Tid i <strong>minuter</strong> det tar att förbereda produkter inför leverans
		</p>
		<p class="help is-danger">{errors.preparetionTime || ""}</p>
	</div> -->

	<div class="field" on:click={() => (data.isVegan = !data.isVegan)}>
		<div class="control">
			<input
				id="available"
				class="checkbox"
				type="checkbox"
				bind:checked={data.isVegan}
			/>
			Produkten är vegansk
		</div>
		<p class="help">Produkten kommer visas som vegansk för kunder</p>
		<p class="help is-danger">{errors.isVegan || ""}</p>
	</div>

	<div
		class="field"
		on:click={() =>
			(data.availableAsGlutenfree = !data.availableAsGlutenfree)}
	>
		<div class="control">
			<input
				id="available"
				class="checkbox"
				type="checkbox"
				bind:checked={data.availableAsGlutenfree}
			/>
			Produkten går att få glutenfri
		</div>
		<p class="help">Produkten går att beställa utan gluten</p>
		<p class="help is-danger">{errors.availableAsGlutenfree || ""}</p>
	</div>

	<div
		class="field"
		on:click={() => (data.availableForOrder = !data.availableForOrder)}
	>
		<div class="control">
			<input
				id="available"
				class="checkbox"
				type="checkbox"
				bind:checked={data.availableForOrder}
			/>
			Tillgänglig att köpa
		</div>
		<p class="help">
			Styr om produkten alls visas för kunder när de gör beställning
		</p>
		<p class="help is-danger">{errors.availableForOrder || ""}</p>
	</div>

	<div
		class="field"
		on:click={() => (data.availableForTakeAway = !data.availableForTakeAway)}
	>
		<div class="control">
			<input
				id="takeAway"
				class="checkbox"
				type="checkbox"
				bind:checked={data.availableForTakeAway}
			/>
			Tillgänglig för Take Away
		</div>
		<p class="help">
			Innebär att produkten kan tas med från resturangen, om inte kan detta
			val inte göras vid beställning
		</p>
		<p class="help is-danger">{errors.availableForTakeAway || ""}</p>
	</div>

	<div class="level mt-6">
		<div class="level-left">
			<div class="level-item">
				{#if isModifying}
					<button class="button is-danger is-outlined" on:click={onDelete}
						>Radera produkt</button
					>
				{/if}
			</div>
		</div>
		<div class="level-left">
			<div class="level-item">
				<button
					class="button is-link is-medium"
					disabled={!validation.ok}
					on:click={onSave}>{saveText}</button
				>
			</div>
		</div>
	</div>
</div>
