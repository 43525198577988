import { Order, validateDocument } from "~/api";
import { derived } from "svelte/store";
import { createLocalStore } from "./_localStore";

type CustomerName = Order['customerName'] | undefined;
type CustomerPhoneNr = Order['customerPhoneNr'] | undefined;

/**The customer name stored in local storage */
export const customerName = createLocalStore<CustomerName>('customer-name', undefined);

/** The customer Phone number stored in local storage */
export const customerPhoneNr = createLocalStore<CustomerPhoneNr>('customer-phone-nr', undefined);

/** Indicates that the current customer information is valid */
export const customerInformationIsValid = derived([customerName, customerPhoneNr], ([$customerName, $customerPhoneNr]) => {
	const { ok } = validateDocument("customerInfo", { customerName: $customerName, customerPhoneNr: $customerPhoneNr });
	return ok;
});