<script lang="ts">
	import { validateDocument } from "~/api";

	import {
		selectedBookingPage,
		selectTimeSlot,
		deliveryWindow,
		productRows,
		confirmOrder,
		timeSlot,
		virtualOrder as order,
		invalidTimeSelected,
	} from "~/stores/booking";
	import {
		customerInformationIsValid,
		customerName,
		customerPhoneNr,
	} from "~/stores/customerInfo";
	import { dwDate } from "~/_utils";
	import CartItem from "./CartItem.svelte";

	$: validation = validateDocument("customerInfo", { customerName: $customerName, customerPhoneNr: $customerPhoneNr });
	
	let orderSent = false;
</script>

{#if $deliveryWindow && $productRows && $timeSlot && $order}
	<!-- Products -->
	<div class="has-text-centered mb-3">
		<div class="header is-size-4 mb-3 has-text-weight-semibold is-family-monospace">
			<span>
				Du är på väg att beställa {$productRows.length == 1
					? "en pizza"
					: `${$productRows.length} pizzor`}{$order.isTakeAway
					? " för take away:"
					: " att äta här:"}
			</span>
		</div>
	</div>

	<!-- Products -->
	<div class="mt-2 mb-4 has-text-left" id="cart">
		<div class="notification is-info is-light">
			{#each $productRows as productRow}
				<CartItem item={productRow} />
			{/each}
		</div>
	</div>

	<div class="columns">
		<!-- Order Time -->
		<div class="column has-text-centered">
			<div class="header is-size-4 has-text-weight-semibold is-family-monospace">
				<span>
					Din tid:&nbsp;{$timeSlot.time}
				</span>
			</div>
			<div class="is-size-6">
				{dwDate($deliveryWindow).toFormat("DDDD")}
			</div>
			<button
				class="button is-light is-primary is-inverted is-rounded mt-3"
				on:click={() => selectTimeSlot(-1, false)}
			>
				<span class="icon is-small"><ion-icon name="time" />&nbsp;</span>
				<span>Ändra tid / take away</span>
			</button>
		</div>

		<!-- Order Price -->
		<div class="column has-text-centered">
			<div class="header is-size-4 has-text-weight-semibold is-family-monospace">
				<span>
					Att betala:&nbsp;{$order.price} kr
				</span>
			</div>
			<div class="is-size-6">
				Betalning görs på plats när du hämtar din order
			</div>
			<button
				class="button is-light is-primary is-inverted is-rounded  mt-3"
				on:click={() => ($selectedBookingPage = "products")}
			>
				<span class="icon is-small"><ion-icon name="add" />&nbsp;</span>
				<span>Lägg till pizza</span>
			</button>
		</div>
	</div>

	<div class="has-text-centered mb-6">
		{#if $order.isTakeAway}
			<span class="icon is-large">
				<ion-icon name="walk" />
			</span>
			<div class="is-size-6">
				Pizzorna är klara att hämta till tiden för din beställning.<br />
				Vi rekommenderar starkt att ni värmer pizzan innan ni ska äta den.<br
				/>
				Sätt ugnen på 200 grader. Öppna och fäll locket under kartongen, stoppa
				in pizzan med kartongen.<br />
				Max 2 minuter. Ät direkt!
			</div>
		{:else}
			<span class="icon is-large">
				<ion-icon name="restaurant" />
			</span>
			<div class="is-size-6">
				Kom gärna 15 minuter innan din tid, köp nått att dricka och slå er
				ner.<br />
				Pizzorna levereras i den takt dom blir klara.
			</div>
		{/if}
	</div>

	<div class="mt-2">
		<!-- Customer information and confirm -->
		<div class="notification is-success is-light">
			<div class="field">
				<label for="customerName" class="label">Ditt namn</label>
				<div class="control">
					<input
						type="text"
						id="customerName"
						class="input is-rounded"
						bind:value={$customerName}
					/>
				</div>
			</div>
			<div class="field">
				<label for="customerPhoneNr" class="label">Ditt telefonnummer</label
				>
				<div class="control">
					<input
						type="text"
						id="customerPhoneNr"
						class="input is-rounded"
						bind:value={$customerPhoneNr}
					/>
				</div>
				<p class="help">{validation.errors?.customerPhoneNr ? "För att beställa måste du ange ett telefonnummer" : ""}</p>
			</div>

			{#if validation.summary}
				<div class="is-size-5 has-text-warning-dark p-2">{validation.errors?.customerName || validation.errors?.customerPhoneNr || ""}</div>
			{/if}
			<button
				class="button is-rounded is-fullwidth is-success has-hover-effect mt-3"
				class:is-loading={orderSent}
				disabled={!$customerInformationIsValid || $invalidTimeSelected}
				on:click={() => {
					orderSent = true;
					confirmOrder();
				}}>Skicka din beställning!</button
			>
		</div>
	</div>
{/if}
