<script lang="ts">
	import { connectionStatus } from "./stores/connectionStatus";
	import { route } from "~/stores/url";

	import Admin from "./pages/Admin.svelte";
	import Book from "./pages/Book.svelte";
	import Order from "./pages/Order.svelte";
	import Pong from "./pages/Pong.svelte";
	import Loader from "./components/general/Loader.svelte";
</script>


{#if $connectionStatus == "connected"}
	{#if $route.group == "admin"}
		<Admin />
	{:else if $route.group == "order" }
		<Order />
	{:else if $route.group == "pong" }
		<Pong />
	{:else }
		<Book />
	{/if}
{:else}
	<Loader label={"Ansluter till Bröts bokningssytem 🍕"} />
{/if}