<script lang="ts">
import { slide } from 'svelte/transition'
import type { Product } from "~/api";
import { createSubscription, createLocalSubscription } from '~/api';
import { productRows, mostAppropiateBookingPage, setMostAppropiateBookingPage, selectedBookingPage } from '~/stores/booking';
import { onMount, onDestroy } from "svelte";

import StupidProductItem from "./StupidProductItem.svelte";
import ProductItem from "./ProductItem.svelte";
import CartItem from "./CartItem.svelte";

// All available products
let _products : Array<Product> = [];

// User selected product filters
let veganFilter : boolean = false;
let glutenFilter : boolean = false;

// Whenever we are in menu mode where booking has not started
export let menu : boolean = false;

// Non-auto subscription to products 
const subscription = createSubscription("products");

// Filter and sort available products when new data is received
subscription.onData(data => {
	_products = data.filter(p => p.availableForOrder).sort((a, b) => {
		if (!a.isVegan && b.isVegan) {
			return -1;
		}
		if (a.isVegan && !b.isVegan) {
			return 1;
		}
		return 0;
	});
});

// Filter non-vegan / non-gluten products when selected
$: products = _products.filter(prod => {
	if (veganFilter && !prod.isVegan) {
		return false;
	}
	if (glutenFilter && !prod.availableAsGlutenfree) {
		return false;
	}
	return true;
});

// Lifecycle hooks

onMount(async () => {
	subscription.start();
});

onDestroy(async () => {
	subscription.stop();
});
	
</script>

<div class="has-text-centered mb-3">
	<div class="header is-size-4 mb-3 has-text-weight-semibold is-family-monospace">
		{#if glutenFilter && veganFilter}
			Här ser du våra <u>veganska</u> och <u>glutenfria</u> pizzor!
		{:else if glutenFilter}
			Här ser du alla våra otroliga <u>glutenfria</u> pizzor!
		{:else if veganFilter}
			Här ser du alla våra underbara <u>veganska</u> pizzor!
		{:else}
			Här ser du alla våra fantastiska pizzor!
		{/if}
	</div>
	<div style="text-align:center; margin-top: 15px; margin-bottom: 15px;">
		<button
			class="button is-primary is-small"
			class:is-light={glutenFilter || veganFilter}		
			on:click={() => {
			glutenFilter = false;
			veganFilter = false;
			}}
		>
				<span>Alla</span>
		</button>
		<button
			class="button is-success is-small"
			class:is-light={!veganFilter}
			on:click={() => veganFilter = !veganFilter}
		>
				<span>Veganskt</span>
		</button>
		<button
			class="button is-warning is-small"
			class:is-light={!glutenFilter}
			on:click={() => glutenFilter = !glutenFilter}
		>
				<span>Glutenfritt</span>
		</button>
	</div>
	<div class="is-size-6">Pizzan gräddas i vedeldade ugn i Napolitansk stil med inspiration från svensk matkultur.</div>
	<div class="is-size-6">(Vi hanterar valnöt, hasselnöt, jordnöt, mandel, cashew och mjöl i lokalen.)</div>
</div>
<div class="mt-2">
	{#if menu}
	{#each products as product}
		<StupidProductItem product={product} glutenFree={glutenFilter ? true : undefined} />
	{/each}
	{:else}
	{#each products as product}
		<ProductItem product={product} glutenFree={glutenFilter ? true : undefined} />
	{/each}
	{/if}
</div>
{#if menu} 
	<div style="text-align:center; margin-top: 30px;">
	<button
		class="button is-primary is-rounded"
		on:click={() => selectedBookingPage.set("deliverywindows")}
	>
			<span>Gör din beställning</span>
	</button>
</div>
{:else if $productRows.length}
<div style="height:60px;"/>
<div class="mt-1" id="cart">
	<div class="notification is-info is-sticky" in:slide={{ delay: 200, duration: 1000 }}>
	<div class="has-text-centered mb-3">
		<div class="header is-size-5 mb-2 has-text-weight-semibold">
			I din kundvagn
		</div>
	</div>
	{#each $productRows as row}
		<CartItem small item={row}/>
	{/each}
		<div style="text-align:center;">
			<button
				class="button mt-4 is-success has-hover-effect"
				on:click={() => setMostAppropiateBookingPage()}
			>
			{#if $mostAppropiateBookingPage == "checkout"}
				<span>Redo att skicka din beställning?</span>
			{:else}
				<span>Gå vidare och välj tid</span>
			{/if}
			</button>
	</div>
	</div>
</div>
{/if}