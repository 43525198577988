import type { DateTime } from "luxon";
import { derived, get, readable, Readable } from "svelte/store";
import { deleteDocument, DeliveryWindow, fetchDocument, Order } from "~/api";
import { currentDateTime, dwDate, timeSlotIsUpcomming } from "~/_utils";
import { route } from "./url";
import { createDerivedAPIStore } from "./_apiStore";

// The order id given through the url
const orderId = derived(route, ($route) => {
	if ($route.params.orderId) {
		return $route.params.orderId;
	}
	return undefined;
});

// The current DateTime, updated every minute
export const now = readable<DateTime>(currentDateTime(), (set) => {
	set(currentDateTime());

	const interval = setInterval(() => {
		set(currentDateTime());
	}, 30000);

	return () => clearInterval(interval);
})

/** The currently selected order */
export const order = createDerivedAPIStore(orderId, 'orders');


/** The delivery window of the selected order */
export const deliveryWindow = derived<[Readable<Order | undefined>], DeliveryWindow | undefined>([order], ([$order], set) => {
	if ($order) {
		fetchDocument("deliverywindows", $order.deliveryWindowId).then(deliveryWindow => set(deliveryWindow));
	} else {
		set(undefined);
	}
});

/** The time slot of the selected order */
export const timeSlot = derived([order, deliveryWindow], ([$order, $deliveryWindow]) => {
	return $order && $deliveryWindow
		? $deliveryWindow.timeSlots[$order.timeSlotIndex]
		: undefined;
});

/** The order is still upcomming: 0: not upcomming, 1: in a number of days, 2: today */
export const isUpcomming = derived([order, deliveryWindow, timeSlot, now], ([$order, $deliveryWindow, $timeSlot, $now]) => {
	if ($order && $deliveryWindow && $timeSlot) {
		if (dwDate($deliveryWindow).startOf('day') >= $now.startOf('day')) {
			if (dwDate($deliveryWindow).ordinal == $now.ordinal) {
				if (timeSlotIsUpcomming($deliveryWindow, $timeSlot)) {
					return 2;
				}
			}
			return 1;
		}
	}
	return 0;
});

// NOTE: Old code for user abortion of order
// export const cancelOrder = async () => {
// 	try {
// 		const $order = get(order);
// 		if (!$order) {
// 			return;
// 		}
// 		else if (($order.deliveryStatus == "confirmed" || $order.deliveryStatus == "submitted") && !confirm("Är du säker? Ta med ditt ordernummer när du hämtar din beställning!")) {
// 			return;
// 		}
// 		else if ($order.deliveryStatus == "temporary" && $order.orderRows.length && !confirm('Är du säker på att vill avbryta din beställning?')) {
// 			return;
// 		}
// 		const updatedOrder = await deleteDocument('orders', $order.id);
// 		//FIXME: await selectOrder(updatedOrder);
// 	}
// 	catch (err) {
// 		console.error("Failed to cancel the order", err);
// 	}
// }
// }
