<script lang="ts">
	import { DeliveryWindow, modifyDocument } from "~/api";
	import { blur } from "svelte/transition";
	import { createAPIStore } from "~/stores";
	import { dwDate } from "~/_utils";

	import ManufacturingQueueItem from "./ManufacturingQueueItem.svelte";
	import OverviewOrderRow from "./OverviewOrderRow.svelte";
	import TimeTag from "./TimeTag.svelte";

	// Given Delivery Window id
	export let id: string;

	// Selected Delivery Window
	let deliveryWindow = createAPIStore("deliverywindows", id);

	// Orders on the Delivery Window
	$: orders = $deliveryWindow
		? $deliveryWindow.orders.sort((a, b) => {
				if (a.timeSlotIndex > b.timeSlotIndex) {
					return 1;
				}
				if (b.timeSlotIndex > a.timeSlotIndex) {
					return -1;
				}
				return 0;
		  })
		: [];

	// UI
	$: title = $deliveryWindow
		? dwDate($deliveryWindow).toFormat("DDDD").toUpperCase()
		: "";

	// Event handlers

	// Show/hide a delivery window
	const handlePublicationStatus = (deliveryWindow: DeliveryWindow) => () => {
		modifyDocument("deliverywindows", deliveryWindow.id, {
			visible: !deliveryWindow.visible,
		});
	};
</script>

{#if $deliveryWindow}
	<h1 class="title"><strong>{title}</strong></h1>
	<h2 class="subtitle is-clickable" on:click={handlePublicationStatus($deliveryWindow)}>
		{#if $deliveryWindow.visible}
			<span class="has-text-link-dark">Synlig för kunder!</span>
		{:else}
			<span class="has-text-danger-dark">Ej synlig för kunder</span>
		{/if}
	</h2>
	<nav class="breadcrumb has-arrow-separator">
		<ul>
			{#each $deliveryWindow.timeSlots as timeSlot}
				<TimeTag deliveryWindow={$deliveryWindow} {timeSlot} />
			{/each}
		</ul>
	</nav>

	<h2 class="title is-4 mt-6 has-text-centered">
		<strong>Beställningar</strong>
	</h2>
	<h2 class="subtitle has-text-centered is-6">
		Här kan du se de beställningar som har gjors listade enligt leveranstid.
		Du kan markera en tid som klar eller avbryten.<br />
		Om du avbryter en order så görs tiden tillgänglig igen i bokningssystemet.
	</h2>
	<div class="box" in:blur>
		<table class="table is-fullwidth">
			<tbody>
				<tr>
					<th>Tid</th>
					<th>Kund</th>
					<th>Pris</th>
					<th>Beställningsnr</th>
					<th>Pizzor</th>
				</tr>
			</tbody>
			<tbody>
				{#each orders as order}
					<OverviewOrderRow deliveryWindow={$deliveryWindow} {order} />
				{/each}
			</tbody>
		</table>
	</div>

	<h2 class="title is-4 mt-6 has-text-centered">
		<strong>Tillverkningskö för {$deliveryWindow.day}</strong>
	</h2>
	<h2 class="subtitle has-text-centered is-6">
		Här kan du se vilka pizzor som bör in i ugnen för vilken leverenstid.<br
		/>
		klicka på den <span class="has-text-link">blåa-rutan</span> för att se
		vilken order pizzan tillhör och när den måste vara klar.<br /><br />
		Tillverkningskön skapas automatiskt från beställningar i bokningssystemet.
	</h2>
	{#each $deliveryWindow.manufacturingQueue as item}
		<ManufacturingQueueItem deliveryWindow={$deliveryWindow} {item} />
	{/each}
{/if}
