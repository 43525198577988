<script lang="ts">
	import type { DeliveryWindowDocument, DeliveryWindowTimeSlot } from "~/api";
	import { dataset_dev, onMount } from "svelte/internal";
	import { blur } from "svelte/transition";
	import { DateTime } from "luxon";
	import { createAPIStore } from "~/stores";
	import { fetchDocument } from "~/api";
	import { gotoRoute } from "~/stores";
	import {
		validateDocument,
		createDocument,
		deleteDocument,
		modifyDocument,
	} from "~/api";

	// Given delivery day id or undefined if the delivery window is new
	export let id: string | undefined = undefined;

	// Indicates that form is either for editing an existing document, or creating a new delivery window
	let isModifying = !!(id && id.length);

	// All delivery windows for comparison
	let allDeliveryWindows = createAPIStore("deliverywindows");

	// The data to work with, with any default values
	let deliveryWindow: Partial<DeliveryWindowDocument> = {
		day: DateTime.now().plus({ days: 1 }).toFormat("yyyy-MM-dd"),
		visible: false,
		timeSlots: [],
	};
	let originalTimeSlots: Array<DeliveryWindowTimeSlot> = [];
	let distanceBetweenTimeSlots: number = 30;
	let productsPerTimeSlot: number = 5;
	let firstTime: DeliveryWindowTimeSlot["time"] = "16:00";
	let lastTime: DeliveryWindowTimeSlot["time"] = "20:00";

	// Current editor status
	$: documentValidation = validateDocument("deliverywindows", deliveryWindow);
	$: generationValidation = validateDocument("timeSlotGenerator", {
		firstTime,
		lastTime,
		productsPerTimeSlot,
		distanceBetweenTimeSlots,
	});
	$: errors = { ...documentValidation.errors, ...generationValidation.errors };
	$: dayAlreadyExists =
		!isModifying &&
		$allDeliveryWindows.find((dw) => dw.day == deliveryWindow.day);
	$: canSave = documentValidation.ok && generationValidation.ok && !dayAlreadyExists;
	
	// UI
	$: dateText = deliveryWindow.day && !errors.day ?DateTime.fromFormat(deliveryWindow.day, "yyyy-MM-dd").setLocale('sv').toFormat("DDDD") : undefined;
	$: titleText = isModifying
		? "Ändra dag och leveranstider"
		: "Lägg till en ny dag med leveranstider";
	$: saveText =
		(dayAlreadyExists ? "Denna dag finns redan" : "") ||
		(!canSave ? "Fixa valideringsproblemen ovan" : "") ||
		(isModifying ? "Spara ändringar" : "Spara nytt leveransfönster");

	// Event handlers

	// Fetch any existing data on mount
	onMount(async () => {
		try {
			// Fetch any existing data
			const existingData = id
				? await fetchDocument("deliverywindows", id)
				: undefined;
			if (existingData) {
				deliveryWindow = { ...deliveryWindow, ...existingData };

				// Store the original time slots
				originalTimeSlots = existingData.timeSlots;

				// Found last time of the delivery window document
				lastTime =
					existingData.timeSlots[existingData.timeSlots.length - 1].time;

				// Found first time of the delivery window document
				firstTime = existingData.timeSlots[0].time;
				// Found duration between timeSlots
				distanceBetweenTimeSlots =
					DateTime.fromFormat(lastTime, "HH:mm")
						.diff(DateTime.fromFormat(firstTime, "HH:mm"))
						.as("minutes") /
						(existingData.timeSlots.length - 1);
				// Found products per time slot
				productsPerTimeSlot =
					existingData.timeSlots[0].availableProductDeliveries;
			} else {
				id = undefined;
				isModifying = false;
			}

			// Generate time slots
			generateTimeSlots();

		} catch (err) {
			console.error(err);
			gotoRoute("admin-list-deliverywindows");
		}
	});

	// Generate the new time slots for the document
	function generateTimeSlots() {
		const { ok } = validateDocument("timeSlotGenerator", {
			firstTime,
			lastTime,
			productsPerTimeSlot,
			distanceBetweenTimeSlots,
		});
		if (!ok) {
			return [];
		}

		const newTimeSlots: Array<DeliveryWindowTimeSlot> = [];
		const first = DateTime.fromFormat(firstTime, "HH:mm");
		const last = DateTime.fromFormat(lastTime, "HH:mm");

		let next = DateTime.fromObject(first.toObject());
		while (next <= last) {
			newTimeSlots.push({
				availableProductDeliveries: productsPerTimeSlot,
				time: next.toFormat("HH:mm") as DeliveryWindowTimeSlot["time"],
			});
			next = next.plus({ minutes: distanceBetweenTimeSlots });
		}
		deliveryWindow.timeSlots = newTimeSlots;
	}

	// Save the data
	const onSave = async () => {
		const validation = validateDocument("deliverywindows", deliveryWindow);
		if (validation.ok && canSave) {
			if (isModifying && id) {
				await modifyDocument("deliverywindows", id, validation.document);
			} else {
				await createDocument("deliverywindows", validation.document);
			}
			gotoRoute("admin-list-deliverywindows");
		}
	};

	// Delete the data from the database
	const onDelete = async () => {
		const isSure = confirm("Är du säker? Dagen går inte att återställa.");
		if (isSure) {
			if (isModifying && id) {
				await deleteDocument("deliverywindows", id);
				gotoRoute("admin-list-deliverywindows");
			}
		}
	};
</script>

<h1 class="title">{titleText}</h1>
<h2 class="subtitle">Använd detta formulär för att redigera vilka tider som finns tillgängliga för denna dag. En öppetid (dag) består av <strong>leveranstider</strong>, vilket är de tider som kunden kan välja att beställa till.<br/><br/>
	Skriv in tiden för den första och sista leveranstiden samt mellanrummet mellan tiderna för att skapa dem.</h2>
<div class="box" in:blur>
	<div class="field">
		<label for="name" class="label">Datum</label>
		<div class="control">
			<input
				id="day"
				placeholder="YYYY-MM-DD"
				class="input is-medium"
				type="text"
				bind:value={deliveryWindow.day}
			/>
		</div>
		{#if dateText}
			<p class="help">
				<strong>Kunder kommer kunna beställa pizzor dessa tider till {dateText}</strong>
			</p>
		{:else}
			<p class="help">Ange datumet då kunder kan beställa</p>
		{/if}
		<p class="help is-danger">{errors.day || ""}</p>
	</div>
	<div class="columns is-multiline is-mobile mt-6">
		<div class="column is-two-thirds">
			<div class="field">
				<label for="name" class="label">Första leveranstid</label>
				<div class="control">
					<input
						id="day"
						placeholder="HH:MM"
						class="input"
						type="text"
						on:change={() => generateTimeSlots()}
						bind:value={firstTime}
					/>
				</div>
				<p class="help">
					Kunder kan beställa pizzor från och med denna tid.
				</p>
				<p class="help is-danger">{errors.firstTime || ""}</p>
			</div>
		</div>
		<div class="column is-one-third">
			<div class="field">
				<label for="name" class="label">Produkter per tid</label>
				<div class="control">
					<input
						id="day"
						placeholder="pizzor som får plats i ugnen"
						class="input"
						type="number"
						on:change={() => generateTimeSlots()}
						bind:value={productsPerTimeSlot}
					/>
				</div>
				<p class="help">Antalet pizzor som kan göras till varje tid</p>
				<p class="help is-danger">{errors.productsPerTimeSlot || ""}</p>
			</div>
		</div>
		<div class="column is-two-thirds">
			<div class="field">
				<label for="name" class="label">Sista leveranstid</label>
				<div class="control">
					<input
						id="day"
						placeholder="HH:MM"
						class="input"
						type="text"
						on:change={() => generateTimeSlots()}
						bind:value={lastTime}
					/>
				</div>
				<p class="help">
					Kunder kan beställa pizzor som längst fram till denna tid
				</p>
				<p class="help is-danger">{errors.lastTime || ""}</p>
			</div>
		</div>
		<div class="column is-one-third">
			<div class="field">
				<label for="name" class="label">Tid mellan leveranstider</label>
				<div class="control">
					<input
						id="day"
						placeholder="minuter"
						class="input"
						type="number"
						on:change={() => generateTimeSlots()}
						bind:value={distanceBetweenTimeSlots}
					/>
				</div>
				<p class="help">Ange tiden i minuter</p>
				<p class="help is-danger">
					{errors.distanceBetweenTimeSlots || ""}
				</p>
			</div>
		</div>
	</div>

	<div class="columns mt-6 is-mobile">
		{#if isModifying}
			<div class="column">
				<h2 class="subtitle">Nuvarande leveranstider</h2>
				<table class="table is-hoverable is-striped is-fullwidth">
					<thead>
						<tr>
							<th>Leveranstid</th>
							<th>Tillgänglighet</th>
						</tr>
					</thead>
					<tbody>
						{#each originalTimeSlots || [] as timeSlot}
							<tr>
								<td>
									<strong>{timeSlot.time}</strong>
								</td>
								<td>
									{timeSlot.availableProductDeliveries}
								</td>
							</tr>
						{/each}
					</tbody>
				</table>
			</div>
		{/if}
		<div class="column">
			<h2 class="subtitle">
				<strong>Nya leveranstider</strong>{#if errors.timeSlots}<span
						class="has-text-danger">&nbsp;{errors.timeSlots}</span
					>{/if}
			</h2>
			<table class="table is-hoverable is-striped is-fullwidth">
				<thead>
					<tr>
						<th>Leveranstid</th>
						<th>Tillgänglighet</th>
					</tr>
				</thead>
				<tbody>
					{#each deliveryWindow.timeSlots || [] as timeSlot}
						<tr>
							<td>
								<strong>{timeSlot.time}</strong>
							</td>
							<td>
								{timeSlot.availableProductDeliveries}
							</td>
						</tr>
					{/each}
				</tbody>
			</table>
		</div>
	</div>

	<div class="level mt-6">
		<div class="level-left">
			<div class="level-item">
				{#if isModifying}
					<button class="button is-danger is-outlined" on:click={onDelete}
						>Radera dag</button
					>
				{/if}
			</div>
		</div>
		<div class="level-left">
			<div class="level-item">
				<button
					class="button is-link is-medium"
					disabled={!canSave}
					class:is-light={!canSave}
					class:has-text-warning-dark={!canSave}
					on:click={onSave}>{saveText}</button
				>
			</div>
		</div>
	</div>
</div>
