<script lang="ts">
	import type { DeliveryWindow } from "~/api";
	import { scale } from "svelte/transition";
	import { queueItemIsSelectable, timeSlotIsUpcomming } from "~/_utils";
	import {
		selectTimeSlot,
		numberOfWantedProducts,
		now
	} from "~/stores/booking";
import { tsDate } from "~/../shared/_utils";

	// Given Delivery Window
	export let deliveryWindow: DeliveryWindow;

	// Given Time Slot Index
	export let index: number;

	// Disables take away if given
	export let cantTakeAway = false;
	
	// Disables resturant if given
	export let cantResturant = false;

	// TimeSlot at given index
	$: timeSlot = deliveryWindow.timeSlots[index];

	// Manufacturing Queue item at given index
	$: queueItem = deliveryWindow.manufacturingQueue[index];

	// Upcoming?
	$: upcomming = timeSlotIsUpcomming(deliveryWindow, timeSlot);

	// Selectable?
	$: selectableForResturant = queueItemIsSelectable(queueItem, false, $numberOfWantedProducts) && upcomming;
	$: selectableForTakeAway = queueItemIsSelectable(queueItem, true, $numberOfWantedProducts) && upcomming;

	// Cool pizzas?
	$: suboptimal =
		queueItem.availableProductDeliveries < $numberOfWantedProducts;
</script>

<div class="divider is-size-4 is-family-monospace" style="margin-top: 0; margin-bottom: 0;">{timeSlot.time}</div>
<div class="columns is-mobile is-centered">
	<div class="column is-half-mobile is-one-third-tablet is-one-quarter-desktop has-text-centered">
		<button
			class="button is-rounded is-fullwidth mb-2"
			class:is-primary={selectableForResturant}
			class:has-text-primary-light={suboptimal && selectableForResturant}
			in:scale
			on:click={selectableForResturant && !cantResturant ? () => selectTimeSlot(index, false) : undefined}
			disabled={!selectableForResturant || cantResturant}
		>
			<span class="icon is-size-4">
				<ion-icon name="restaurant" />
			</span>
			<span class="is-size-5">
				&nbsp;Ät hos oss
			</span>
		</button>
		{#if selectableForResturant && !cantResturant}
		<p class="tag is-fullwidth is-medium">{queueItem.maxNumberOfProductsAvailableForResturant} {queueItem.maxNumberOfProductsAvailableForResturant == 1 ? "pizza": "pizzor"} tillgängliga.</p>
		<p class="has-text-grey">Kom gärna femton minuter innan utsatt tid: {tsDate(deliveryWindow, timeSlot).minus({minutes: 15}).toFormat("HH:mm")}.</p>
		{/if}
	</div>
	<div class="column is-half-mobile is-one-third-tablet is-one-quarter-desktop has-text-centered">
		<button
			class="button is-rounded is-fullwidth mb-2"
			class:is-primary={selectableForTakeAway}
			class:has-text-primary-light={suboptimal && selectableForTakeAway}
			in:scale
			on:click={selectableForTakeAway && !cantTakeAway ? () => selectTimeSlot(index, true) : undefined}
			disabled={!selectableForTakeAway || cantTakeAway}
		>
			<span class="icon is-size-4">
				<ion-icon name="walk" />
			</span>
			<span class="is-size-5">
				&nbsp;Ta med dig hem
			</span>
		</button>
		{#if selectableForTakeAway && !cantTakeAway}
		<p class="tag is-fullwidth is-medium">{queueItem.maxNumberOfProductsAvailableForTakeAway} {queueItem.maxNumberOfProductsAvailableForTakeAway == 1 ? "pizza": "pizzor"} tillgängliga.</p>
		<p class="has-text-grey">Dina pizzor är klara att hämtas {timeSlot.time}.</p>
		{/if}
	</div>
</div>