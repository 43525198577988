<script lang="ts">
	import type { Product } from "~/api";
	import {
		unableToAddProducts,
		selectedBookingPage,
		unableToFitAdditionalProducts,
	} from "~/stores/booking";
	import { blur } from "svelte/transition";
	import CartItem from "./CartItem.svelte";

	// Given Product
	export let product: Product;
	
	// Is gluten free selected?
	export let glutenFree: boolean = false;
	
	// Price of the product
	$: price = product.price + (glutenFree ? 20 : 0);
	
</script>

<article class="notification is-info is-light" in:blur>
	<div class="columns">
		<div class="column is-narrow is-hidden-mobile is-hidden">
			<figure class="image is-128x128 mr-4">
				<img
					alt="bild på {product.name}"
					src="https://bulma.io/images/placeholders/128x128.png"
				/>
			</figure>
		</div>
		<div class="column is-narrow">
			<div class="content">
				<p class="title is-size-4">{product.name}</p>
				<p class="subtitle is-size-5">
					{price} kr
				</p>
			</div>
		</div>
		<div class="column" />
		<div class="column is-half is-align-items-flex-start">
			<div class="content">
				<p class="is-size-5">
					{product.description}
				</p>
			</div>
			<!-- Tags -->
			<div class="tags">
				{#if product.isVegan}
					<span class="tag is-success is-medium is-family-monospace">
						<span class="icon is-small"><ion-icon name="thumbs-up" /></span
						><strong>&nbsp;Vegan</strong>
					</span>
				{/if}
				{#if !product.availableForTakeAway}
					<span class="tag is-primary is-light is-medium is-family-monospace">
						<span class="icon is-small"><ion-icon name="alert-circle-outline" /></span
						><strong>&nbsp;Ej för take away</strong>
					</span>
				{/if}
				{#if product.availableAsGlutenfree && glutenFree}
					<span
						class="tag is-warning is-medium is-family-monospace"
						class:is-light={!glutenFree}
					>
						Glutenfri
					</span>
				{/if}
			</div>
		</div>
	</div>
</article>
