import type P5 from "p5";
import { DateTime } from "luxon";
import { writable } from "svelte/store";
import { createLocalStore, createSingletonStore } from "~/stores";

export const tries = writable(0);
export const points = writable(0);
export const maxPoints = createLocalStore("almost-pong", 0);
export const alltimeHighscore = createSingletonStore("highscore");
export const sessionHighscore = createSingletonStore("session-highscore");

export const createGame = (playerName : string) => (p5: P5, node: HTMLElement) => {
	// Game settings variables
	const boardWidth = 800;
	const boardHeight = 600;
	const ballWidth = 15;
	const ballHeight = ballWidth;
	const paddleWidth = ballWidth;
	const speed = 8;
	const gravity = 0.5;
	const strength = 10;
	const minPaddleHeight = ballHeight;
	const maxPaddleHeight = 200;
	const paddleAnimationSpeed = 0.5;
	const paddleMovementSpeed = 4;
	const paddleHeightChangePerPoint = ballHeight / 2;
	const leftPaddleX = 0;
	const rightPaddleX = boardWidth - paddleWidth;

	// Drawing variables
	const elementWidth = Math.floor(node.clientWidth);
	const xScale = elementWidth / boardWidth;
	const yScale = ((elementWidth / 4) * 3) / boardHeight;

	// Game state variables
	let started = false;
	let direction = Math.random() > 0.7;
	let upwardforce = strength;
	let paddleHeight = maxPaddleHeight;
	let leftPaddleY = boardHeight / 2 - paddleHeight / 2;
	let rightPaddleY = boardHeight / 2 - paddleHeight / 2;
	let paddleTargetHeight = maxPaddleHeight;
	let leftPaddleTargetY = boardHeight / 2 - paddleHeight / 2;
	let rightPaddleTargetY = boardHeight / 2 - paddleHeight / 2;
	let ballX = Math.round(boardWidth / 2 - ballWidth / 2);
	let ballY = Math.round(boardHeight / 2 - ballWidth / 2);

	// Handle contact events between the ball and paddles
	function onContact() {
		// Change direction
		direction = !direction;

		// Decrese the paddle height
		paddleTargetHeight = Math.max(
			minPaddleHeight,
			paddleHeight - paddleHeightChangePerPoint
		);

		// Add a point!
		points.update(p => p + 1);

		// Randomize paddle positions
		if (direction) {
			leftPaddleTargetY = Math.floor(
				Math.random() * (boardHeight - paddleHeight)
			);
		} else {
			rightPaddleTargetY = Math.floor(
				Math.random() * (boardHeight - paddleHeight)
			);
		}
	}

	// Handle touch & press events
	function onPress() {
		// Ignore presses outside the canvas
		if (p5.mouseY < 0 || p5.mouseY > boardHeight * yScale || p5.mouseX < 0 || p5.mouseX > boardWidth * xScale) {
			return;
		}

		// Jump
		upwardforce = strength;

		// Start the game and reset the points
		if (!started) {
			started = true;
			points.update(p => 0);
		}
	}

	// Handle game over events
	function onGameOver() {

		// Check and update high scores
		points.update(p => {
			maxPoints.update(mp => Math.max(mp, p));

			alltimeHighscore.update(hs => {
				if (hs && hs.entries) {
					let i = 0
					for (; i < hs.entries.length && i < 3; i++) {
						var entry = hs.entries[i];
						if (p >= entry.points) {
							break;
						}
					}
					hs.entries.splice(i, 0, { name: `${playerName} (${DateTime.now().toFormat("yyyy-MM-dd")})`, points: p });
					hs.entries = hs.entries.filter((_, index) => index < 3);
				}
				return hs;
			});
			
			sessionHighscore.update(hs => {
				if (hs && hs.entries) {
					let i = 0
					for (; i < hs.entries.length && i < 3; i++) {
						var entry = hs.entries[i];
						if (p >= entry.points) {
							break;
						}
					}
					hs.entries.splice(i, 0, { name: `${playerName} (${DateTime.now().toFormat("yyyy-MM-dd")})`, points: p });
					hs.entries = hs.entries.filter((_, index) => index < 3);
				}
				return hs;
			});

			return p
		});

		// Next try! (resets the game)
		tries.update(value => value + 1);
	}

	// Handle step events
	function onStep() {
		// Ball movement
		if (started) {
			ballX += (direction ? -1 : 1) * speed;
			upwardforce -= gravity;
			ballY -= upwardforce;
		}

		// Paddle animation and movement
		if (paddleHeight > paddleTargetHeight) {
			paddleHeight -= paddleAnimationSpeed;
		}
		if (leftPaddleY > leftPaddleTargetY) {
			leftPaddleY -= paddleMovementSpeed;
		}
		if (leftPaddleY < leftPaddleTargetY) {
			leftPaddleY += paddleMovementSpeed;
		}
		if (rightPaddleY > rightPaddleTargetY) {
			rightPaddleY -= paddleMovementSpeed;
		}
		if (rightPaddleY < rightPaddleTargetY) {
			rightPaddleY += paddleMovementSpeed;
		}

		// Check for contact between the ball and the paddles
		if (
			(direction &&
				ballX <= paddleWidth &&
				ballY + ballHeight >= leftPaddleY &&
				ballY <= leftPaddleY + paddleHeight) ||
			(!direction &&
				ballX >= rightPaddleX - ballWidth &&
				ballY + ballHeight >= rightPaddleY &&
				ballY <= rightPaddleY + paddleHeight)
		) {
			onContact();
		}

		// Check for if the ball is outside the board
		if (
			ballY > boardHeight + ballHeight ||
			ballX < -ballWidth ||
			ballX > boardWidth + ballWidth
		) {
			onGameOver();
		}
	}

	// Setup action
	function setup() {
		// Set framerate
		p5.frameRate(54);
		// Create the board
		const canvas = p5.createCanvas(
			boardWidth * xScale,
			boardHeight * yScale
		);
		//canvas.parent("#game");
	}

	// Draw action
	function draw() {
		// Create a step event
		onStep();

		// Clear the screen
		p5.background(0, 0, 0);

		// Foreground colors
		p5.stroke(255, 255, 255);
		p5.fill(255, 255, 255);

		// Draw paddles
		p5.rect(
			leftPaddleX * xScale,
			leftPaddleY * yScale,
			paddleWidth * xScale,
			paddleHeight * yScale
		);
		p5.rect(
			rightPaddleX * xScale,
			rightPaddleY * yScale,
			paddleWidth * xScale,
			paddleHeight * yScale
		);

		// Draw the ball
		p5.rect(
			ballX * xScale,
			ballY * yScale,
			ballWidth * xScale,
			ballWidth * yScale
		);
	}

	// Register events to the p5 instance
	p5.setup = setup;
	p5.draw = draw;
	p5.touchStarted = onPress;
	p5.mousePressed = onPress;
}