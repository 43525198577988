<script lang="ts">
	import { createAPIStore } from "~/stores";
	import { slide } from "svelte/transition";
	import {
		selectDeliveryWindow,
		numberOfWantedProducts,
		deliveryWindowsExists,
		deliveryWindows as allDeliveryWindows,
	} from "~/stores/booking";
	import { dwDate, dwValid } from "~/_utils";

	// Sorted delivery windows
	$: deliveryWindows = $allDeliveryWindows
		? $allDeliveryWindows.sort((a, b) => {
				if (a.day > b.day) {
					return 1;
				}
				if (b.day > a.day) {
					return -1;
				}
				return 0;
		  })
		: [];
</script>

{#if $deliveryWindowsExists}
	<div class="has-text-centered mb-3">
		<div class="header is-size-4 mb-3 has-text-weight-semibold is-family-monospace">
			Vi har öppet följande dagar:
		</div>
		<div class="is-size-6">
			Nya tider läggs ut löpande, välj en dag för att se lediga tider...<br/>
	</div>
	</div>
	<div class="mt-2">
		<div class="columns is-multiline is-centered is-mobile">
			{#each deliveryWindows as deliveryWindow}
				<div class="column is-narrow has-text-centered">
					{#if dwValid(deliveryWindow, $numberOfWantedProducts)}
						<div
							class="notification is-primary has-text-primary-dark is-fullwidth has-text-centered has-hover-effect"
							in:slide
							on:click={() => selectDeliveryWindow(deliveryWindow)}
						>
							<h1 class="is-family-monospace" style="font-size: 2rem;">
								{dwDate(deliveryWindow).setLocale('sv').toFormat(
									"EEEE'en' 'den' dd"
								)}{#if dwDate(deliveryWindow).daysInMonth <= 2}:a{:else}:e{/if}
							</h1>
							<h1 class="subtitle is-family-monospace is-size-5">
								{dwDate(deliveryWindow).setLocale("sv").toFormat("MMMM y")}
							</h1>
						</div>
					{:else}
						<div
							class="notification is-light is-info is-fullwidth has-text-centered has-text-grey-light"
							in:slide
						>
							<h1 class="is-family-monospace" style="font-size: 2rem;">
								{dwDate(deliveryWindow).setLocale("sv").setLocale('sv').toFormat(
									"EEEE'en' 'den' dd"
								)}{#if dwDate(deliveryWindow).daysInMonth <= 2}:a{:else}:e{/if}
							</h1>
							<h1 class="subtitle is-family-monospace is-size-5">
								{dwDate(deliveryWindow).setLocale("sv").toFormat("MMMM y")}
							</h1>
						</div>
					{/if}
				</div>
			{/each}
		</div>
	</div>
{/if}