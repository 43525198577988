<script lang="ts">
	import P5 from "p5";

	/** Set the seed to a new value to reset the game */
	export let seed: number = 0;

	/** Method used to initialize the game */
	export let creator: (
		p5: P5,
		node: HTMLElement,
		seed: number
	) => void = () => {};

	function setup(node: HTMLElement, seed: number) {
		let context = new P5((p5) => {
			creator(p5, node, seed);
		}, node);

		return {
			update(seed: number) {
				creator(context, node, seed);
			},
			destroy() {
				// Destroy P5 on onmount
				context?.remove();
			},
		};
	}
</script>

<div class="p5-canvas {$$props.class}" use:setup={seed} on:click={(e) => e.stopPropagation()} />

<style>
	.p5-canvas {
		user-select: none;
	}
</style>
