<script lang="ts">
	import type { DeliveryWindow, DeliveryWindowTimeSlot } from "~/api";
	import { timeSlotIsUpcomming } from "~/_utils";
	import { now } from "~/stores/booking";

	// Given Delivery Window
	export let deliveryWindow: DeliveryWindow;
	// Given Time Slot
	export let timeSlot: DeliveryWindowTimeSlot;

	// Indicates the the time slot is in the future
	$: isUpcomming = timeSlotIsUpcomming(deliveryWindow, timeSlot, $now);
</script>

<li class:has-text-weight-bold={isUpcomming}><a href={`#${timeSlot.time}`}>{timeSlot.time}</a></li>
