<script lang="ts">
	import type {
		DeliveryWindow,
		Order,
		Product,
	} from "../../../../shared/definitions";
	import { createAPIStore, linkTo } from "~/stores";
	import { blur } from "svelte/transition";

	// All products to list
	const products = createAPIStore("products");
</script>

<h1 class="title">Produkter</h1>
<h2 class="subtitle">Klicka på en rad för att redigera produkten</h2>
<div class="box" in:blur>
	<table class="table is-hoverable is-fullwidth is-striped">
		<tbody>
			<tr>
				<th>Namn</th>
				<th>Pris</th>
				<th>Beskrivning</th>
				<th>Tillgänglighet</th>
			</tr>
		</tbody>
		<tbody>
			{#each $products as product}
				<tr on:click={linkTo("admin-edit-product", { id: product.id })}>
					<td>
						<strong>{product.name}</strong>
					</td>
					<td>
						{product.price} kr
					</td>
					<td>
						{product.description}{#if product.isVegan}&nbsp;<span class="has-text-success-dark"
						>Vegansk!</span>{/if}
					</td>
					<td>
						{#if product.availableForOrder}
							<span class="has-text-link-dark">Tillgänglig</span
							>{#if !product.availableForTakeAway}<span
									class="has-text-warning-dark"
									>&nbsp;(Ej Take Away)</span
								>{/if}
						{:else}
							<span class="has-text-danger-dark"
								>Inte tillgänglig för köp</span
							>
						{/if}
					</td>
				</tr>
			{/each}
		</tbody>
	</table>
	<div class="block has-text-right mt-6">
		<button
			class="button is-link is-rounded"
			on:click={linkTo("admin-new-product")}><strong>Lägg till produkt</strong></button
		>
	</div>
</div>
