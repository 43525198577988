import type { Socket } from "socket.io-client";
import { io } from "socket.io-client";
import Emittery from "emittery";
import type {
  ConnectionStatusName,
  ActionEventName,
  SubscriptionAction,
  CollectionName,
  PassiveEventName,
  SubscriptionDetails,
  ActionParameters,
} from "../../shared";
import type {
  CollectionDocuments,
  Document as DefaultDocument,
} from "../../shared";
import { createSubscriptionPath, createShortId } from "../../shared";

export function createSubscription<K extends keyof CollectionDocuments>(
  collection: K,
  id?: undefined
): Subscription<
  CollectionDocuments[K][1],
  CollectionDocuments[K][0],
  Array<CollectionDocuments[K][0]>,
  Array<CollectionDocuments[K][0]>
>;
export function createSubscription<K extends keyof CollectionDocuments>(
  collection: K,
  id?: string
): Subscription<
  CollectionDocuments[K][1],
  CollectionDocuments[K][0],
  CollectionDocuments[K][0],
  undefined
>;

export function createSubscription<K extends keyof CollectionDocuments>(
  collection: K,
  id?: string | undefined
) {
  // Create a subscription to a specific document
  if (id && id.length) {
    return new Subscription<
      CollectionDocuments[K][1],
      CollectionDocuments[K][0],
      CollectionDocuments[K][0],
      undefined
    >({ collection, id }, undefined);
  }

  // Create a subscription to all documents in the collection
  else {
    return new Subscription<
      CollectionDocuments[K][1],
      CollectionDocuments[K][0],
      Array<CollectionDocuments[K][0]>,
      Array<CollectionDocuments[K][0]>
    >({ collection }, []);
  }
}

export class Subscription<
  Document extends DefaultDocument,
  Projection extends Document,
  ReturnValue extends Projection | Array<Projection> | undefined,
  DefaultValue extends Projection | Array<Projection> | undefined
> {
  //#region static subscription fields
  private static _socket: Socket;
  private static _connectionStatus: ConnectionStatusName = "disconnected";
  public static _eventEmitter: Emittery = new Emittery(); // NOTE: preferable not public

  private static setConnectionStatus(status: ConnectionStatusName) {
    this._connectionStatus = status;
    console.log(`[SUBSCRIPTION] connection status: ${status}`);
    this._eventEmitter.emit("status", status);
  }

  private static getSocket(): Socket {
    if (!this._socket) {
      // Any cached customer id is sent when the clients socket is established for the first time
      this._socket = io();

      // When the socket is connected...
      this._socket.on("connect", () => {
        this.setConnectionStatus("connected");
      });
      this._socket.on("disconnect", () => {
        this.setConnectionStatus("disconnected");
      });
    }
    return this._socket;
  }

  public static get status(): string {
    return Subscription._connectionStatus;
  }

  public static connect(): void {
    this.getSocket();
  }
  //#endregion

  private readonly _details: SubscriptionDetails;
  private readonly _defaultValue: DefaultValue;

  private _handler: (data: ReturnValue) => Promise<void> | void;
  private _eventEmitter: Emittery = new Emittery();
  private _listening: boolean;
  private _value: ReturnValue | DefaultValue;

  private onNewData(data: ReturnValue) {
    this._value = data || this._defaultValue;
    this._eventEmitter.emit("data", this._value);
  }

  private emitEvent(event: PassiveEventName) {
    Subscription.getSocket().emit(event, this._details);
  }
  private emitAction<
    K extends keyof ActionParameters,
    Result extends ActionParameters[K][1]
  >(event: K, data: ActionParameters[K][0]): Promise<Result> {
    return new Promise<Result>((resolve, reject) => {
      const actionId: string = createShortId();
      const action: SubscriptionAction<unknown> = {
        ...this._details,
        data,
        actionId,
      };
      Subscription.getSocket().once(
        actionId,
        (response: { data: Result; ok: boolean; error?: string }) => {
          if (response.ok) {
            resolve(response.data);
          } else {
            console.log("[API]", response);
            reject(response.error);
          }
        }
      );
      Subscription.getSocket().emit(event, action);
    });
  }

  public get path(): string {
    return createSubscriptionPath(this._details);
  }

  constructor(requestOptions: SubscriptionDetails, defaultValue: DefaultValue) {
    this._details = requestOptions;
    this._value = defaultValue;
    this._defaultValue = defaultValue;
    this._listening = false;
    this._handler = this.onNewData.bind(this);
  }

  public onData(
    handler: Subscription<
      Document,
      Projection,
      ReturnValue,
      DefaultValue
    >["_handler"]
  ) {
    this._eventEmitter.on("data", handler);
  }

  public onStatus(
    handler: (status: ConnectionStatusName) => Promise<void> | void
  ) {
    Subscription._eventEmitter.on("status", handler);
  }

  public fetch(
    id: ActionParameters["fetch"][0]
  ): Promise<ActionParameters["fetch"][1]> {
    console.log(
      `[SUBSCRIPTION] fetching a document with id ${id} from ${this.path}, listening for updates: ${this._listening}`
    );
    return this.emitAction("fetch", id);
  }

  public create(
    input: ActionParameters["create"][0]
  ): Promise<ActionParameters["create"][1]> {
    console.log(
      `[SUBSCRIPTION] creating a document at ${this.path}, listening for updates: ${this._listening}`
    );
    return this.emitAction("create", input);
  }

  public modify(
    id: Document["id"],
    update: Partial<Document>
  ): Promise<ActionParameters["modify"][1]> {
    console.log(
      `[SUBSCRIPTION] updating the ${this._details.collection} document with id ${id}, listening for updates: ${this._listening}`
    );
    return this.emitAction("modify", { ...update, id });
  }

  public delete(
    id: ActionParameters["delete"][0]
  ): Promise<ActionParameters["delete"][1]> {
    console.log(
      `[SUBSCRIPTION] deleting the ${this._details.collection} document with id ${id}, listening for updates: ${this._listening}`
    );
    return this.emitAction("delete", id);
  }

  public get(): ReturnValue | DefaultValue {
    return this._value || this._defaultValue;
  }

  public stop() {
    if (this._listening) {
      Subscription.getSocket().off(this.path, this._handler);
      this.emitEvent("unsubscribe");
      this._listening = false;
      this._eventEmitter.clearListeners("data");
      console.log(
        `[SUBSCRIPTION] Stopped listening to ${this.path}, all onData events removed`
      );
    }
  }

  public start() {
    if (this._eventEmitter.listenerCount("data") == 0) {
      console.log(
        `[SUBSCRIPTION] can't start a subscription to ${this.path}, no events are registered`
      );
    } else if (!this._listening) {
      Subscription.getSocket().on(this.path, this._handler);
      this.emitEvent("subscribe");
      this._listening = true;
      console.log(
        `[SUBSCRIPTION] Started listening to updates at ${this.path}`
      );
    }
  }
}
