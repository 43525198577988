import { writable, get, derived } from "svelte/store";
import { createAPIStore } from "./_apiStore";
import { io } from "socket.io-client";
import { Order, fetchDocument } from "~/api";

interface Message {
  id: string | number;
  title: string;
  body?: string;
}

// Socket listener for notification for each each new order
const keepTrackOfNewOrders = () => {
  const socket = io();
  socket.on("/notifications/orders/create", async (orderId) => {
    const order = await fetchDocument("orders", orderId);
    const deliveryWindow = await fetchDocument(
      "deliverywindows",
      order.deliveryWindowId
    );

    addMessage({
      id: Math.random(),
      title: `Det har kommit en ny beställning till ${deliveryWindow.day}!`,
      body: `Beställare: ${order.customerName}. Antal pizzor: ${order.orderRows.length}`,
    });
  });
};
// Spawn the listener
keepTrackOfNewOrders();

// The Audio to play on notifications
const audio = new Audio(
  "https://cdn.freesound.org/previews/242/242671_950925-lq.mp3"
);

/** Notifications that should be shown to the admin  */
export const adminMessages = writable<Array<Message>>([]);

/** Adds a new notification */
export async function addMessage(mess: Message) {
  // Update the store of notifications
  const currentMessages = get(adminMessages);
  adminMessages.set([...currentMessages, mess]);

  // Try to play audio
  try {
    audio.play();
  } catch {
    console.error("Could not play audio");
  }

  // Check if the browser supports notifications
  if (Notification && document.visibilityState == "hidden") {
    // Request notification access
    new Notification(mess.title, {
      body: mess.body,
    });
  }
}

/** Removes the given new notification */
export async function removeMessage(mess: Message) {
  // Update the store of notifications
  const keeptMessages = get(adminMessages).filter((m) => m.id != mess.id);
  adminMessages.set(keeptMessages);
}

/** Request notifications access */
export async function enableNotifications() {
  try {
    // Check if the browser supports notifications
    if (Notification) {
      // Request notification access
      await Notification.requestPermission();
    }
  } catch (err) {
    console.error("Could not enable notifications", err);
  }
}
