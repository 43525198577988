<script>
	import { blur } from "svelte/transition";
	import Map from "./Map.svelte";

	import img1 from "../../assets/images/brot/1.jpg";
	import img2 from "../../assets/images/brot/2.jpg";
	import img3 from "../../assets/images/brot/3.jpg";
	import img4 from "../../assets/images/brot/4.jpg";
	import img5 from "../../assets/images/brot/5.jpg";
	import img6 from "../../assets/images/brot/6.jpg";
	import img7 from "../../assets/images/brot/7.jpg";
	import img8 from "../../assets/images/brot/8.jpg";

	// Shuffle the available images
	const images = [
		{ src: img1, sort: Math.random() },
		{ src: img2, sort: Math.random() },
		{ src: img3, sort: Math.random() },
		{ src: img4, sort: Math.random() },
		{ src: img5, sort: Math.random() },
		{ src: img6, sort: Math.random() },
		{ src: img7, sort: Math.random() },
		{ src: img8, sort: Math.random() }
	].sort((a,b) => a.sort - b.sort);
</script>

<div in:blur class="is-family-monospace">
	<div class="has-text-centered">
		<img class="img" src={images[0].src} alt="background" />
		<img class="img" src={images[1].src} alt="background" />
		<img class="img" src={images[2].src} alt="background" />
	</div>
	<div class="notification has-text-link has-text-centered is-clickable has-hover-effect" on:click={() => window.location.href = "http://www.bröt.se"}>
		Gå till vår hemsida!
	</div>
	<div class="is-size-4 has-text-centered mt-4">
		Ur elden och in i munnen!<br />
		Mat är livet och livet ska delas med släkt och vänner. Precis som vår pizza.<br
		/>
		Pizzan gräddas i vedeldade ugn i Napolitansk stil med inspiration från svensk
		matkultur.<br />
		<br />
		<div class="notification is-info is-family-sans-serif is-size-5 mx-6">
		Detta innebär att vi använder ett väldigt finmalet mjöl, vatten, salt och färsk jäst. Pizzan jäser i rumstemperatur i 18-24 timmar och i varje pizza är det enbart 0,06g jäst.
		<br />
		Vår glutenfria pizza är gjord på en mjölblandning som innehåller: glutenfri vetestärkelse, dextros, majsstärkelse, bovetemjöl, risstärkelse, västfibrer(psyllium), förtjockningsmedel: guar; smaktillsats. Det finns mycket mjöl i luften och den glutenfria pizzan gräddas i samma ugn som den andra pizzan.
		</div>
		<br />
		Det finns såklart möjligheten att beställa på plats. Detta i mån av tid och
		hur många pizzadegar som finns kvar.
	</div>
	<p class="is-size-4 has-text-centered mt-6 mb-6">
		<strong>Adress</strong><br />
		🍕Bröt Surdegsbageri & Pizza<br />
		<strong>Kilavägen 11</strong><br />
		<strong>51492 Uddebo</strong><br />
		<a href="tel:0705812555">070-581 25 55</a><br />
		<a href="mailto:jalle.brot@väveriet.se">jalle.brot@väveriet.se</a><br />
	</p>
	<Map />
	<p class="is-size-5 has-text-centered mt-6 mb-6">
		Bokningssystemet är utvecklad av <a href="https://maskinrepubliken.se">Maskinrepubliken</a>, hoppas det smakar! :)<br />
	</p>
</div>

<style>
	.img {
		border-radius: 20px;
		position: relative;
		width: 32%;
		height: 32%;
	}
</style>
