<script lang="ts">
	import { slide, blur } from "svelte/transition";
	import SelectCookiePolicyStep from "../components/components/AcceptCookiePolicySheet.svelte";
	import ProductGallery from "../components/content/ProductGallery.svelte";

	import { cookiesAllowed } from "~/stores/cookiesAllowed";
	import { customerInformationIsValid } from "~/stores/customerInfo";
	import {
		hasAddedProducts,
		numberOfWantedProducts,
		deliveryWindow,
		timeSlot,
		selectedBookingPage,
		productRows,
		selectDeliveryWindow,
		invalidTimeSelected,
		deliveryWindowsExists,
	} from "~/stores/booking";
	import { prompt } from "~/stores/prompt";

	import Information from "../components/content/Information.svelte";
	import Presentation from "../components/content/Presentation.svelte";
	import DeliveryWindowGallery from "../components/content/DeliveryWindowGallery.svelte";
	import TimeSlotGallery from "../components/content/TimeSlotGallery.svelte";
	import CheckOut from "../components/content/Cart.svelte";
	import Prompt from "~/components/content/Prompt.svelte";
    import CartItem from "~/components/content/CartItem.svelte";

	// Indicates that a prompt exists that should be shown
	$: hasPrompt = $prompt && $prompt.visible;

	// Indicates that the prompt, if it exists, should be expanded
	let showPromptExpanded = false;
</script>

<main class="p-3">
	<div class="container is-widescreen">
		<!-- Header -->
		<Presentation />
		<!-- Tabs -->
		<div
			class="notification is-info remove-bottom-padding"
			class:remove-bottom-padding={!showPromptExpanded}
		>
			<div class="level has-text-centered">
				<div
					class="level-item has-hover-effect is-clickable"
					on:click={() => selectedBookingPage.set("info")}
				>
					Beställ din&nbsp;<strong>🍕 Pizza Napoletana</strong>&nbsp;hos
					oss!
				</div>
			</div>
			<div class="level" class:translucent={showPromptExpanded}>
				<div class="level-left">
					{#if !(($deliveryWindow && $timeSlot) || $hasAddedProducts)}
					<div class="level-item">
						<button
							class="button is-fullwidth is-info"
							class:is-light={$selectedBookingPage != "menu"}
							on:click={() => ($selectedBookingPage = "menu")}
						>
							<span class="icon is-small">
								<ion-icon name="pizza-outline" />
							</span>
							<span>
									Vår pizza-meny!
							</span>
						</button>
					</div>
					{/if}
					<div class="level-item">
						<span class="has-text-info-dark">{$deliveryWindow && $timeSlot ? "1" : ""}&nbsp;</span><button
							class="button is-fullwidth is-primary"
							class:is-light={$selectedBookingPage != "deliverywindows" && ($deliveryWindow && $timeSlot)}
							on:click={() => {
								selectDeliveryWindow(undefined);
								$selectedBookingPage = "deliverywindows";
							}}
						>
							<span class="icon is-small">
								<ion-icon name="time-outline" />
							</span>
							<span>
								&nbsp;
								{#if ($deliveryWindow && $timeSlot) || $hasAddedProducts}
								{#if ($deliveryWindow && $timeSlot)}
								<span>
									{$deliveryWindow.day}&nbsp;{$timeSlot.time}
								</span>
								{:else}
								<span>
									Välj dag & tid
								</span>
								{/if}
								{:else}
									Påbörja beställning
								{/if}
							</span>
						</button>
					</div>
					{#if ($deliveryWindow && $timeSlot) || $hasAddedProducts}
					<div class="level-item">
						<span class="has-text-info-dark">2&nbsp;</span><button
							class="button is-fullwidth is-primary"
							class:is-light={$selectedBookingPage != "products" || $productRows.length}
							on:click={() => ($selectedBookingPage = "products")}
						>
							<span class="icon is-small">
								<ion-icon name="pizza-outline" />
							</span>
							<span>
								&nbsp;
								{#if $hasAddedProducts && $numberOfWantedProducts > 1}
									{$numberOfWantedProducts} Pizzor valda
								{:else if $hasAddedProducts}
									{$numberOfWantedProducts} Pizza vald
								{:else}
									Välj Pizzor
								{/if}
							</span>
						</button>
					</div>
					<div class="level-item">
						<span class="has-text-info-dark">3&nbsp;</span><button
							class="button is-fullwidth is-primary"
							disabled={!$timeSlot || !$productRows.length}
							class:is-light={(!$timeSlot && !$productRows.length) || $selectedBookingPage == "checkout"}
							class:has-text-bold={$selectedBookingPage == "checkout"}
							class:is-success={ !(!$timeSlot || !$productRows.length) || $selectedBookingPage == "checkout"}
							on:click={() => ($selectedBookingPage = "checkout")}
						>
							{#if !$timeSlot || !$productRows.length}
								<span class="icon is-small"
									><ion-icon name="cart-outline" />
								</span>
							{:else}
								<span class="icon is-small"
									><ion-icon name="cart" /></span
								>
							{/if}
							<span class="">&nbsp;Skicka beställning</span>
						</button>
					</div>
				{/if}
				</div>
				<div class="level-right is-hidden-mobile">
					<div class="level-item">
						<button
							class="button is-info"
							class:is-light={$selectedBookingPage == "info"}
							on:click={() => ($selectedBookingPage = "info")}
						>
							<span>
								<span class="icon is-small"
									><ion-icon name="home" />&nbsp;</span
								>
								<span class="">Om oss</span>
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<!-- Special messages -->
		{#if hasPrompt && $prompt}
			<Prompt data={$prompt} bind:showPromptExpanded={showPromptExpanded} />
		{/if}
		<div style="height:30px"/>
		<div in:blur={{ delay: 800 }}>
			{#if !$deliveryWindowsExists}
				<div class="notification is-warning has-text-centered">
					<p>
						<strong
							>Ojdå! Just nu finns det inga dagar att boka på 🤷‍♀️</strong
						>
					</p>
					<p>
						Prova att komma tillbaka lite senare, vi lägger löpande ut nya
						öppetider i bokningsystemet :)
					</p>
				</div>
			{:else if $invalidTimeSelected}
				<div class="notification is-danger has-text-centered">
					<p>
						<strong
							>Obs! Den tiden du valt går det inte att beställa till
							längre</strong
						>
					</p>
					<!-- svelte-ignore a11y-missing-attribute -->
					<p>
						Någon annan han tyvärr före. 😬 <a
							on:click={() => selectedBookingPage.set("deliverywindows")}
							>Välj en annan tid som passar!</a
						>
					</p>
				</div>
			{/if}
			<!-- Tab contents -->
			{#if !hasPrompt || !showPromptExpanded}
				{#if $selectedBookingPage == "info"}
					<Information />
				{:else if $selectedBookingPage == "menu"}
					<ProductGallery menu={true} />
				{:else if $selectedBookingPage == "products"}
					<ProductGallery />
				{:else if $selectedBookingPage == "deliverywindows"}
					{#if $deliveryWindow}
						<TimeSlotGallery />
					{:else}
						<DeliveryWindowGallery />
					{/if}
				{:else if $selectedBookingPage == "checkout"}
					<CheckOut />
					<!-- FIXME: re-add Cookie confirmation-->
					<!-- {:else if !$cookiesAllowed}
					<SelectCookiePolicyStep /> -->
				{/if}
			{/if}
		</div>
		<div style="height: 10rem" />
	</div>
</main>

<style>
	.remove-bottom-padding {
		margin-bottom: 0;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	.level {
		transition: opacity .5s ease-in-out;
	}
	.translucent {
		opacity: 0.2;
	}
</style>
