import { CollectionDocuments, CollectionName, Document as DefaultDocument, createSubscription, DeliveryWindow, Subscription } from "~/api";
import {} from '~/api';
import { readable, derived, Readable } from "svelte/store";

/** Store interface */
interface APIStore<T> {
	subscribe: (subscription: (value: T) => void) => (() => void)
}

/** Creates a readable Svelte Store for all documents in the given collection */
export function createAPIStore <
	K extends keyof CollectionDocuments,
	R extends Array<CollectionDocuments[K][0]>
>
(collection: K, id?: undefined) : APIStore<R>

/** Creates a readable Svelte Store for a specific document in the given collection */
export function createAPIStore <
	K extends keyof CollectionDocuments,
	R extends CollectionDocuments[K][0] | undefined
>
(collection: K, id?: string) : APIStore<R>

// Function implementation
export function createAPIStore<K extends keyof CollectionDocuments>(collection: K, id?: string | undefined) {
	const subscription = createSubscription(collection, id);

	const defaultValue = subscription.get();
	
	const { subscribe } = readable(defaultValue, function start(set) {

		// Load any existing data
		set(subscription.get());
		
		// Start the subscription
		subscription.onData(value => {
			set(value);
		});
		subscription.start();
		
		return function stop() {
			subscription.stop();
		};
	});

	return {
		subscribe: subscribe
	};
}

// TODO: document
export function createDerivedAPIStore<
	K extends keyof CollectionDocuments
>
(idStore : Readable<string | undefined>, collection : K) 
: Readable<CollectionDocuments[K][0] | undefined> {
	return derived(idStore, (id, set) => {
		const subscription = createSubscription(collection, id || "please-ignore-me");
		
		// Load any existing data
		set(subscription.get());
		
		// Start the subscription
		subscription.onData(value => {
			set(value);
		});
		subscription.start();
		
		
		return function stopOrUpdate() {
			subscription.stop();
		}
	});
}