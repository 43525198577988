import UniversalRouter, { RouteContext, RouteParams, RouterContext, Routes,  } from 'universal-router'
import generateUrls from 'universal-router/generateUrls'

// ROUTES CONFIG

export interface Route<Params = {}> extends RouteConfig<Params> {
	name: RouteName
}

export interface RouteConfig<Params = {}> {
	path: string
	group : string
	params: Params
}

const route: <Params = {}>(path: string, group?: string) => RouteConfig<Params> = (path, group = "") => ({
	path : path,
	group : group,
	params: {} as any
})


const routes = <const>{
	"start": route("/", "start"),
	"admin": route("/admin", "admin"),
	"admin-list-products": route("/admin/products", "admin"),
	"admin-new-product": route("/admin/product/new", "admin"),
	"admin-edit-product": route<{ id: string }>("/admin/product/:id", "admin"),
	"admin-list-deliverywindows": route("/admin/deliverywindows", "admin"),
	"admin-new-deliverywindow": route("/admin/deliverywindows/new", "admin"),
	"admin-edit-deliverywindow": route<{ id: string }>("/admin/deliverywindow/:id/edit", "admin"),
	"admin-edit-prompt": route("/admin/prompt", "admin"),
	"admin-edit-pong": route("/admin/pong", "admin"),
	"admin-view-deliverywindow": route<{ id: string }>("/admin/overview/:id", "admin"),
	"order": route<{ orderId: string }>("/order/:orderId", "order"),
	"pong": route("/pong", "pong")
}

export const defaultRoute = routes["start"];

export const router = new UniversalRouter(Object.entries(routes).map(entry => {
	const [name, config] = entry;
	return ({
		//children: []
		name,
		path: config.path,
		action: ((c: RouteContext<any, RouterContext>) => ({ name, path: config.path, params: c.params, group: config.group }))
	});
}));
const generator = generateUrls(router);

export type RouteName = keyof typeof routes;

export const gotoRoute = <K extends RouteName, Entry extends typeof routes[K]>(key: K, params: Entry['params'] = {}, options: { reload?: boolean } = {}) => {
	const url = generator(key, params);
	history.pushState(null, `Bröt - ${key}`, url);
	if (options.reload) {
		location.reload();
	}
};

export const replaceRoute = <K extends RouteName, Entry extends typeof routes[K]>(key: K, params: Entry['params'] = {}) => {
	const url = generator(key, params);
	history.replaceState(null, `Bröt - ${key}`, url);
};

export const linkTo = <K extends RouteName, Entry extends typeof routes[K]>(key: K, params: Entry['params'] = {}) => () => gotoRoute(key, params);