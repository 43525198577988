<script lang="ts">
	import { blur } from "svelte/transition";
	import { adminMessages, removeMessage, addMessage } from "~/stores/notifications";
</script>

{#each $adminMessages as message}
	<div
		class="notification is-link has-text-left"
		in:blur={{ duration: 1000 }}
		out:blur={{ duration: 500 }}
	>
	<button class="delete" on:click={() => removeMessage(message)}></button>
				<b class="is-size-6">{message.title}</b>
				{message.body || ""}
	</div>
{/each}