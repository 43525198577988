<script lang="ts">
	import { createLocalStore } from "~/stores";

	import Game from "../components/game/Game.svelte";
	let enteredText = "";
	let playerName = createLocalStore("almost-pong-name", "");
	
</script>
<div class="bg"/>
{#if !$playerName}
	<div class="notification has-background-dark has-text-white p-6">
		<div class="level pl-2 pr-2">
			<div class="level-left">
				<div class="level-item">
					<h1 class="title is-3">PONG, TYP</h1>
				</div>
			</div>
		</div>
		<div class="field">
			<label for="playerName" class="label">Ange ditt namn</label
			>
			<div class="control">
				<input
					type="text"
					id="playerName"
					placeholder="Ange ditt namn..."
					class="input is-rounded"
					bind:value={enteredText}
				/>
			</div>
		</div>
		<div class="field has-text-centered">
			<button on:click={() => playerName.set(enteredText)} class="button is-rounded is-black">Börja spela</button>
		</div>
	</div>
{:else}
	<Game fullscreen />
{/if}
<style> 
	.bg {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100vw;
		height: 100vh;
		background-color: hsl(0, 0%, 21%) !important;
	}
</style>