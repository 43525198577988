import type { CollectionDocuments, ActionParameters } from "../../shared";
import { Subscription } from "./subscription";

export async function fetchDocument<
	K extends keyof CollectionDocuments,
	Document extends CollectionDocuments[K][1],
	Projection extends CollectionDocuments[K][0],
	ActionSet extends ActionParameters<Document, Projection & Document>
	>
(collection: K, input: ActionSet['fetch'][0]) : Promise<Projection>
{
	const subscription = new Subscription<CollectionDocuments[K][1], CollectionDocuments[K][0], Array<CollectionDocuments[K][0]>, Array<CollectionDocuments[K][0]>>(
		{ collection },
		[]
	);
	return await subscription.fetch(input) as Projection;
}

export async function createDocument<
	K extends keyof CollectionDocuments,
	Document extends CollectionDocuments[K][1],
	Projection extends CollectionDocuments[K][0],
	ActionSet extends ActionParameters<Document, Projection & Document>
	>
(collection: K, input: ActionSet['create'][0]) : Promise<Projection>
{
	const subscription = new Subscription<CollectionDocuments[K][1], CollectionDocuments[K][0], Array<CollectionDocuments[K][0]>, Array<CollectionDocuments[K][0]>>(
		{ collection },
		[]
	);
	return await subscription.create(input) as Projection;
}


export async function modifyDocument<
	K extends keyof CollectionDocuments,
	Document extends CollectionDocuments[K][1],
	Projection extends CollectionDocuments[K][0]
	>
(collection: K, id: Document['id'], update: Partial<Document>)
 : Promise<Projection>
{
	const subscription = new Subscription<CollectionDocuments[K][1], CollectionDocuments[K][0], Array<CollectionDocuments[K][0]>, Array<CollectionDocuments[K][0]>>(
		{ collection },
		[]
	);
	return await subscription.modify(id, update) as Projection
}


export async function deleteDocument<
	K extends keyof CollectionDocuments,
	Document extends CollectionDocuments[K][1],
	Projection extends  CollectionDocuments[K][0],
	ActionSet extends ActionParameters<Document, Projection & Document>
>
(collection: K, id: Document['id']) : Promise<ActionParameters['delete'][1]> {
	const subscription = new Subscription<CollectionDocuments[K][1], CollectionDocuments[K][0], Array<CollectionDocuments[K][0]>, Array<CollectionDocuments[K][0]>>(
		{ collection },
		[]
	);
	return await subscription.delete(id);
}