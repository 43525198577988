<script lang="ts">
	import type { DeliveryWindowTimeSlot } from "~/api";
	import {
		addedProducts,
		deliveryWindow,
		selectDeliveryWindow,
	} from "~/stores/booking";
	import { dwDate } from "~/_utils";
	import TimeSlotItem from "./TimeSlotItem.svelte";

	// Available Time Slots
	$: timeSlots = !$deliveryWindow ? [] : $deliveryWindow.timeSlots;

	// There are available time slots
	$: timeSlotsExists = !!timeSlots?.length;

	// A pizza in the cart can't be take:d away
	$: cantTakeAway = !!$addedProducts.find(product => !product.availableForTakeAway)
</script>

{#if $deliveryWindow}
	<div class="has-text-centered mb-3 has-text-weight-semibold">
		<div class="header is-family-monospace is-size-4 mb-3">
			Dessa tider är tillgängliga {dwDate($deliveryWindow).toFormat("DDDD")}
		</div>
		<button
			class="button is-light is-info is-inverted is-rounded is-centered mb-3"
			on:click={() => selectDeliveryWindow(undefined)}
		>
			<span>Byt dag</span>
		</button>
	</div>
	
	{#if !timeSlotsExists}
		<div class="notification is-warning">
			<p>Ojdå! Det finns inga lediga tider att välja ifrån 🤷‍♀️</p>
			<p>
				Prova att komma tillbaka lite senare eller välkommen tillbaka en
				annan dag
			</p>
		</div>
	{:else}
		{#each timeSlots as timeSlot, index}
			<TimeSlotItem {index} deliveryWindow={$deliveryWindow} cantTakeAway={cantTakeAway || index == 0} cantResturant={index+1==timeSlots.length}/>
		{/each}
	{/if}
{/if}
