<script lang="ts">
	import { DeliveryWindow, modifyDocument, deleteDocument } from "~/api";
	import { gotoRoute, linkTo } from "~/stores";

	// Given delivery window
	export let item: DeliveryWindow;

	
	// Total number of orders
	let nrOfOrders = item.orders.length;
	
	// Total number of doughs that might be needed
	let maxNrOfPizzas = item.timeSlots.reduce((nr, ts) => nr+ts.availableProductDeliveries, 0);

	// Number of completed or aborted orders
	let nrofInactiveOrders = item.orders.filter((order) => 
		order.deliveryStatus == "done" || order.deliveryStatus == "aborted"
	).length;

	// Number of active orders
	let nrOfActiveOrders = nrOfOrders - nrofInactiveOrders;

	// Event handlers

	// Show/hide a delivery window
	const handlePublicationStatus = (deliveryWindow: DeliveryWindow) => () => {
		modifyDocument("deliverywindows", deliveryWindow.id, {
			visible: !deliveryWindow.visible,
		});
	};


	// Edit a delivery window
	const handleEdit = (deliveryWindow: DeliveryWindow) => () => {
		gotoRoute("admin-edit-deliverywindow", { id: deliveryWindow.id });
	};

	// Remove a delivery window
	// const handleDeletion = (deliveryWindow: DeliveryWindow) => () => {
	// 	if (
	// 		confirm(
	// 			`Är du säker på att du vill ta bort dagen ${deliveryWindow.day}? Detta kan inte ångras!`
	// 		)
	// 	) {
	// 		deleteDocument("deliverywindows", deliveryWindow.id);
	// 	}
	// };
</script>

<tr
	on:click={linkTo("admin-view-deliverywindow", {
		id: item.id,
	})}
>
	<td>
		<strong>{item.day}</strong>
	</td>
	<td>
		{#if nrOfOrders}
			<span
				>{nrOfActiveOrders} kvar{#if nrofInactiveOrders}&nbsp;({nrofInactiveOrders} klara){/if}</span
			>
		{:else}
			<span><i>inga</i></span>
		{/if}
	</td>
	<td>
		{maxNrOfPizzas} st
	</td>
	<td>
		{#if item.visible}
			<b class="has-text-link-dark">Ja</b>
		{:else}
			<span>-</span>
		{/if}
	</td>
	<td>
		<div class="buttons is-fullwidth">
			{#if item.visible}
				<button
					class="button is-small is-warning is-rounded"
					on:click|stopPropagation={handlePublicationStatus(
						item
					)}>Göm</button
				>
			{:else}
				<button
					class="button is-small is-success is-rounded"
					on:click|stopPropagation={handlePublicationStatus(
						item
					)}>Visa</button
				>
			{/if}
			{#if !item.orders.length}
				<button
					class="button is-small is-warning-dark is-rounded"
					on:click|stopPropagation={handleEdit(item)}
					>Ändra</button
				>
			{/if}
		</div>
	</td>
</tr>
